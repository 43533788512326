import compact from 'lodash.compact'
import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'

const JOINT_ACCOUNT_CODE = 'YJR080'

const useAnnualReviewBorrowerSelection = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: businessPartnerRelationships,
    isLoading: isBusinessPartnerRelationshipsLoading,
    isError: isBusinessPartnerRelationshipsError,
    error: businessPartnerRelationshipsError,
  } = useBusinessPartnerRelationships(businessPartnerId)

  const {
    data: businessPartner,
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
    error: businessPartnerError,
  } = useBusinessPartnerById(businessPartnerId)

  const businessPartnerName = businessPartner?.fullName

  const gktoUnitRelationship = businessPartnerRelationships?.unitRelationships.find(
    (unitRelationship) => unitRelationship.id === JOINT_ACCOUNT_CODE,
  )
  const gktoRelationships = gktoUnitRelationship?.relationships.find(
    (relationship) => relationship.head.id === businessPartnerId,
  )
  // gktoMembers equal borrowers in this case
  const gktoMembers = compact(
    [{ id: businessPartnerId, name: businessPartnerName }].concat(
      gktoRelationships?.members.map((member) => ({
        id: member.id,
        name: member.fullName,
      })),
    ),
  )

  const sourceRender = useMemo(() => ({ businessPartnerId }), [businessPartnerId])

  const { isSomeValueError, isSomeValueLoading, error } = useAutomaticTileHookHelper({
    loadingValues: [isBusinessPartnerRelationshipsLoading, isBusinessPartnerLoading],
    errorValues: [isBusinessPartnerRelationshipsError, isBusinessPartnerError],
    errorDetails: [businessPartnerRelationshipsError, businessPartnerError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    // When there is no metadata, this thing will stay disabled and therefore loading --> check after the other finished loading
    // whether now the selected conditions are loading.
    if (isBusinessPartnerRelationshipsLoading || isBusinessPartnerLoading) {
      return {
        isLoading: true,
        isError: false,
      }
    }

    // Everything has loaded --> return the GKTO relationships
    return {
      isLoading: false,
      isError: false,
      data: {
        gktoMembers,
        sourceRender,
        businessPartnerId,
      },
    }
  }, [
    isBusinessPartnerRelationshipsLoading,
    isBusinessPartnerLoading,
    isSomeValueLoading,
    isSomeValueError,
    gktoMembers,
    sourceRender,
    businessPartnerId,
    error,
  ])
}

export default useAnnualReviewBorrowerSelection
