import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Link,
  LinkDesign,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsCellViews.module.css'
import { PERIODS } from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'
import StaffMemberByUserId from 'components/ui/staff-member/StaffMemberByUserId'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'

export const NameAndUsageTypeTableCellView = ({ name, usageTypeName, defaultTableCellValue }) => (
  <FlexBox direction="Column">
    <b>{name ? name : defaultTableCellValue}</b>
    <span>{usageTypeName ? usageTypeName : defaultTableCellValue}</span>
  </FlexBox>
)
NameAndUsageTypeTableCellView.propTypes = {
  name: PropTypes.string,
  usageTypeName: PropTypes.string,
  defaultTableCellValue: PropTypes.string,
}
export const DescriptionTableCellView = ({ description, defaultTableCellValue }) => (
  <FlexBox direction="Column">
    <Text>{description || defaultTableCellValue}</Text>
  </FlexBox>
)
DescriptionTableCellView.propTypes = {
  description: PropTypes.string,
  defaultTableCellValue: PropTypes.string,
}
export const AREA_COLUMN_TYPES = {
  VALUATION: 'valuation',
  RENT_ROLL: 'rent-roll#details',
}
export const AreaColumnTitleCellView = ({ properties, tSegments, areaColumnType }) => {
  const isMultiProperty = properties?.length > 1
  const firstProperty = properties[0]

  const renderText = () => {
    if (areaColumnType === AREA_COLUMN_TYPES.VALUATION) {
      return tSegments('valuation-results-value')
    } else if (areaColumnType === AREA_COLUMN_TYPES.RENT_ROLL) {
      return tSegments('rent-roll-value')
    }
  }

  const renderLink = () => {
    if (firstProperty?.id) {
      const propertyId = firstProperty?.id
      return (
        <>
          {`${tSegments('area')} (`}
          <Link
            design={LinkDesign.Default}
            href={`/properties/${propertyId}/${areaColumnType}`}
            target="_self"
            rel="noreferrer"
          >
            {renderText()}
          </Link>
          {')'}
        </>
      )
    }
  }

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      justifyContent={FlexBoxJustifyContent.End}
      alignItems={FlexBoxAlignItems.End}
    >
      {isMultiProperty && `${tSegments('area')}`}
      {!isMultiProperty && renderLink()}
    </FlexBox>
  )
}
AreaColumnTitleCellView.propTypes = {
  properties: PropTypes.array.isRequired,
  tSegments: PropTypes.func.isRequired,
  areaColumnType: PropTypes.oneOf([AREA_COLUMN_TYPES.VALUATION, AREA_COLUMN_TYPES.RENT_ROLL])
    .isRequired,
}

export const AreaAndMeasureUnitTableCellView = ({
  tSegments,
  totalArea,
  totalAreas,
  totalNumberOfUnits,
  areaMeasureUnitCode,
}) => {
  const formatArea = useAreaMeasurementUnitFormatter()
  const formatAreaNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const calculateAreaSum = (areas) =>
    areas?.reduce((partialSum, addition) => partialSum + (addition ? addition : 0), 0) ?? 0

  const calculateAreaShare = (area, areaSum, formatted = true) => {
    let share = 0
    if (area && areaSum) {
      share = area / areaSum
    }
    return formatted ? formatPercent(share) : share * 100
  }
  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.End}
      alignItems={FlexBoxAlignItems.End}
    >
      {[
        totalArea > 0 && (
          <div key="totalArea">
            <Text className={styles.totalAreaText}>
              {`${formatAreaNumber(totalArea)} ${formatArea(areaMeasureUnitCode)}`}
            </Text>
            <div>
              <div className={styles.areaShare}>
                <Label>{`${tSegments('area.share')}:`}</Label>
                <span> </span>
                <Text>{calculateAreaShare(totalArea, calculateAreaSum(totalAreas))}</Text>
              </div>
            </div>
          </div>
        ),
        totalNumberOfUnits > 0 && (
          <div key="areaPieces">
            <Label>{tSegments('area.pieces') + ':'}</Label>
            <span> </span>
            <Text>{totalNumberOfUnits}</Text>
          </div>
        ),
      ]}
    </FlexBox>
  )
}
AreaAndMeasureUnitTableCellView.propTypes = {
  tSegments: PropTypes.func,
  totalArea: PropTypes.number,
  totalAreas: PropTypes.array,
  totalNumberOfUnits: PropTypes.number,
  areaMeasureUnitCode: PropTypes.string,
}

export const MoneyAndCurrencyTableCellView = ({
  period,
  number,
  currencyCode,
  defaultTableCellValue,
}) => {
  const ANNUALLY_DIVISOR = 12
  const formatMoney = useCustomizableCurrencyFormatter()
  if (number) {
    return (
      <FlexBox
        direction={FlexBoxDirection.Column}
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.End}
      >
        {`${formatMoney(
          period === PERIODS.ANNUALLY ? number : number / ANNUALLY_DIVISOR,
          currencyCode,
        )}`}
      </FlexBox>
    )
  } else {
    return (
      <FlexBox
        direction={FlexBoxDirection.Column}
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.End}
      >
        {defaultTableCellValue}
      </FlexBox>
    )
  }
}
MoneyAndCurrencyTableCellView.propTypes = {
  period: PropTypes.string,
  number: PropTypes.number,
  currencyCode: PropTypes.string,
  defaultTableCellValue: PropTypes.string,
}

export const LastChangeTableCellView = ({
  lastChangeDateTime,
  lastChangeUserAccountId,
  defaultTableCellValue,
}) => {
  const { format } = useShortDateFormatter()

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.End}
      alignItems={FlexBoxAlignItems.End}
    >
      <Text>{format(lastChangeDateTime) || defaultTableCellValue}</Text>
      <Text>
        {(lastChangeUserAccountId && <StaffMemberByUserId s4UserId={lastChangeUserAccountId} />) ||
          defaultTableCellValue}
      </Text>
    </FlexBox>
  )
}
LastChangeTableCellView.propTypes = {
  lastChangeDateTime: PropTypes.string,
  lastChangeUserAccountId: PropTypes.string,
  defaultTableCellValue: PropTypes.string,
}

export const StandardTitleCellView = ({ position, title }) => (
  <FlexBox
    direction={FlexBoxDirection.Column}
    justifyContent={FlexBoxJustifyContent.Center}
    alignItems={position === 'start' ? FlexBoxJustifyContent.Start : FlexBoxJustifyContent.End}
  >
    {title}
  </FlexBox>
)
StandardTitleCellView.propTypes = {
  title: PropTypes.string,
  position: PropTypes.string,
}
