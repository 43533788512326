import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AreaAndMeasureUnitTableCellEdit,
  MonthlyAndAnnuallyMoneyTableCellEdit,
  NameAndUsageTypeTableCellEdit,
  DescriptionTableCellEdit,
  SelectAndUnselectAllTableCellEdit,
  SelectAndUnselectTableCellEdit,
} from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsCellEdits'
import {
  AreaAndMeasureUnitTableCellView,
  AreaColumnTitleCellView,
  AREA_COLUMN_TYPES,
  LastChangeTableCellView,
  MoneyAndCurrencyTableCellView,
  NameAndUsageTypeTableCellView,
  DescriptionTableCellView,
  StandardTitleCellView,
} from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsCellViews'
import {
  isDeletable,
  findSegmentKpisByPropertyUuid,
} from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'

export const useSegmentsTableColumnDefinitions = (
  properties,
  segments,
  segmentsInEdit,
  selectedSegments,
  multiPropertySegmentKpis,
  usageTypeCodes,
  period,
  onAllSelectChange,
  onSelectChange,
  onNameChange,
  onUsageTypeChange,
  onDescriptionChange,
  onTotalAreaChange,
  onMarketRentChange,
  onVacancyCostsChange,
) => {
  const { t: tSegments } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.segments',
  })
  const defaultTableCellValue = ''

  const isAllSelected = useMemo(() => {
    const deletableSegments = segmentsInEdit.filter((segment) =>
      isDeletable(
        segment.uuid,
        findSegmentKpisByPropertyUuid(segment.property_uuid, multiPropertySegmentKpis),
      ),
    )
    return deletableSegments.length > 0 && deletableSegments.length === selectedSegments.length
  }, [multiPropertySegmentKpis, segmentsInEdit, selectedSegments.length])

  const isSelected = (segment) => !!selectedSegments.find((selected) => selected === segment.uuid)

  const getPerPeriodTitle = (column) =>
    tSegments(column, {
      period: period === 'monthly' ? tSegments('month') : tSegments('year'),
    })

  const getDefaultAreaMeasureUnitCodeBySegment = (segment) => {
    const property = properties.find((p) => p.uuid === segment.property_uuid)
    return property?.area_measure_unit_code
  }

  const getDefaultCurrencyCodeBySegment = (segment) => {
    const property = properties.find((p) => p.uuid === segment.property_uuid)
    return property?.currency_code || property?.headquarter_currency
  }

  const getPropertyGroupedSegments = (segment, ungroupedSegments) =>
    ungroupedSegments.filter((s) => s.property_uuid === segment.property_uuid)

  return [
    {
      columnKey: 'select',
      title: (selectable) => (
        <SelectAndUnselectAllTableCellEdit
          selectable={selectable}
          selected={isAllSelected}
          onSelectChangeAll={onAllSelectChange}
        />
      ),
      cellValueEdit: (segment, selectable) => (
        <SelectAndUnselectTableCellEdit
          selectable={selectable}
          selected={isSelected(segment)}
          onSelectChange={(e) => onSelectChange(segment, e)}
        />
      ),
      hideInView: true,
    },
    {
      columnKey: 'name-and-usage-type',
      title: () => (
        <StandardTitleCellView title={tSegments('name-and-usage-type')} position={'start'} />
      ),
      cellValue: (segment) => (
        <NameAndUsageTypeTableCellView
          name={segment?.name}
          usageTypeName={segment?.usage_type_name}
          defaultTableCellValue={defaultTableCellValue}
        />
      ),
      cellValueEdit: (segmentInEdit) => (
        <NameAndUsageTypeTableCellEdit
          segmentInEdit={segmentInEdit}
          usageTypeCodes={usageTypeCodes}
          onChangeName={(segmentUuid, value) => {
            onNameChange(segmentUuid, value)
          }}
          onChangeUsageType={(segmentUuid, value) => {
            onUsageTypeChange(segmentUuid, value)
          }}
        />
      ),
    },
    {
      columnKey: 'description',
      editModeTableCellClassNames: 'editCellFirstLineOnly',
      title: () => <StandardTitleCellView title={tSegments('description')} position={'start'} />,
      cellValue: (segment) => (
        <DescriptionTableCellView
          description={segment?.description}
          defaultTableCellValue={defaultTableCellValue}
        />
      ),
      cellValueEdit: (segmentInEdit) => (
        <DescriptionTableCellEdit
          segmentInEdit={segmentInEdit}
          onChangeDescription={(segmentUuid, value) => {
            onDescriptionChange(segmentUuid, value)
          }}
        />
      ),
    },
    {
      columnKey: 'area-appraisal',
      title: () => (
        <AreaColumnTitleCellView
          properties={properties}
          tSegments={tSegments}
          areaColumnType={AREA_COLUMN_TYPES.VALUATION}
        />
      ),
      cellValue: (segment) => (
        <AreaAndMeasureUnitTableCellView
          tSegments={tSegments}
          totalArea={segment?.total_area}
          totalAreas={getPropertyGroupedSegments(segment, segments).map((s) => s?.total_area)}
          areaMeasureUnitCode={
            segment?.area_measure_unit_code || getDefaultAreaMeasureUnitCodeBySegment(segment)
          }
        />
      ),
      cellValueEdit: (segmentInEdit) => (
        <AreaAndMeasureUnitTableCellEdit
          tSegments={tSegments}
          segmentUuid={segmentInEdit?.uuid}
          totalArea={segmentInEdit?.total_area}
          totalAreas={getPropertyGroupedSegments(segmentInEdit, segmentsInEdit).map((segment) =>
            parseFloat(segment?.total_area),
          )}
          areaMeasureUnitCode={segmentInEdit?.area_measure_unit_code}
          defaultAreaMeasureUnitCode={getDefaultAreaMeasureUnitCodeBySegment(segmentInEdit)}
          onChangeTotalArea={(segmentUuid, value) => {
            onTotalAreaChange(segmentUuid, value)
          }}
        />
      ),
    },
    {
      columnKey: 'area-rent-roll',
      title: () => (
        <AreaColumnTitleCellView
          properties={properties}
          tSegments={tSegments}
          areaColumnType={AREA_COLUMN_TYPES.RENT_ROLL}
        />
      ),
      cellValue: (segment, segmentKpis) => {
        const kpis = segmentKpis?.kpis
        const segmentKpi = kpis?.find((kpi) => kpi?.segment_uuid === segment.uuid)
        const totalAreaSurface = segmentKpi?.total_area_surface
        const totalNumberOfUnits = segmentKpi?.total_number_of_units
        return (
          <AreaAndMeasureUnitTableCellView
            tSegments={tSegments}
            totalArea={totalAreaSurface?.value}
            totalAreas={kpis?.map((kpi) => kpi?.total_area_surface?.value)}
            totalNumberOfUnits={totalNumberOfUnits}
            areaMeasureUnitCode={
              totalAreaSurface?.measurement_unit || getDefaultAreaMeasureUnitCodeBySegment(segment)
            }
          />
        )
      },
      cellValueEdit: (segmentInEdit, segmentKpis) => {
        const totalAreaKpis = segmentKpis?.kpis
        const totalAreaKpi = totalAreaKpis?.find(
          (kpi) => kpi?.segment_uuid === segmentInEdit.uuid,
        )?.total_area_surface
        const totalNumberOfUnits = totalAreaKpis?.find(
          (kpi) => kpi?.segment_uuid === segmentInEdit.uuid,
        )?.total_number_of_units
        return (
          <AreaAndMeasureUnitTableCellEdit
            tSegments={tSegments}
            segmentUuid={segmentInEdit?.uuid}
            totalArea={totalAreaKpi?.value}
            totalAreas={totalAreaKpis?.map((kpi) => kpi?.total_area_surface?.value)}
            totalNumberOfUnits={totalNumberOfUnits}
            areaMeasureUnitCode={totalAreaKpi?.measurement_unit}
            defaultAreaMeasureUnitCode={getDefaultAreaMeasureUnitCodeBySegment(segmentInEdit)}
            onChangeTotalArea={() => {}}
            disabled={true}
          />
        )
      },
    },
    {
      columnKey: 'market-rent',
      title: () => <StandardTitleCellView title={getPerPeriodTitle('market-rent')} />,
      cellValue: (segment) => (
        <MoneyAndCurrencyTableCellView
          period={period}
          number={segment?.market_rent_amount?.number}
          currencyCode={
            segment?.market_rent_amount?.currency || getDefaultCurrencyCodeBySegment(segment)
          }
          defaultTableCellValue={defaultTableCellValue}
        />
      ),
      cellValueEdit: (segmentInEdit) => (
        <MonthlyAndAnnuallyMoneyTableCellEdit
          attribute={'market_rent_amount'}
          segmentInEdit={segmentInEdit}
          defaultCurrency={getDefaultCurrencyCodeBySegment(segmentInEdit)}
          onChangeMoneyValue={(segmentUuid, value) => {
            onMarketRentChange(segmentUuid, value)
          }}
        />
      ),
    },
    {
      columnKey: 'vacancy-costs',
      title: () => <StandardTitleCellView title={getPerPeriodTitle('vacancy-costs')} />,
      cellValue: (segment) => (
        <MoneyAndCurrencyTableCellView
          period={period}
          number={segment?.vacancy_cost_amount?.number}
          currencyCode={
            segment?.vacancy_cost_amount?.currency || getDefaultCurrencyCodeBySegment(segment)
          }
          defaultTableCellValue={defaultTableCellValue}
        />
      ),
      cellValueEdit: (segmentInEdit) => (
        <MonthlyAndAnnuallyMoneyTableCellEdit
          attribute={'vacancy_cost_amount'}
          segmentInEdit={segmentInEdit}
          defaultCurrency={getDefaultCurrencyCodeBySegment(segmentInEdit)}
          onChangeMoneyValue={(segmentUuid, value) => {
            onVacancyCostsChange(segmentUuid, value)
          }}
        />
      ),
    },
    {
      columnKey: 'last-change',
      title: () => <StandardTitleCellView title={tSegments('last-change')} />,
      cellValue: (segment) => (
        <LastChangeTableCellView
          lastChangeDateTime={segment?.system_administrative_data?.last_change_date_time}
          lastChangeUserAccountId={segment?.system_administrative_data?.last_change_user_account_id}
          defaultTableCellValue={defaultTableCellValue}
        />
      ),
      hideInEdit: true,
    },
  ]
}
