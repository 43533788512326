import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useCamelizedResponse, useCombinedQueryResults } from 'hooks/services/queryHelper'

const useTranchesByDeals = ({ dealUuids, dataSource, options = {} }) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (dataSource) urlSearchParams.append('dataSource', dataSource)

    return urlSearchParams
  }, [dataSource])

  const requestsConfig = useMemo(
    () => ({
      requests: dealUuids?.map((dealUuid) => ({
        path: `/financing-structures/deals/${dealUuid}/tranches?${queryParams}`,
        translated: true,
        keys: ['deals', dealUuid, 'tranches', 'dataSource', queryParams],
        options: {
          enabled: !!dealUuid,
          ...options,
        },
      })),
      translated: true,
    }),
    [dealUuids, options, queryParams],
  )

  const {
    data: tranchesData,
    isLoading,
    isError,
  } = useCamelizedResponse(useCombinedQueryResults(useRequests(requestsConfig)))

  const trancheIdsByDeal = {}
  if (!isLoading && !isError) {
    dealUuids?.forEach((dealUuid, index) => {
      const tranchesForDeal = tranchesData?.[index]?.tranches || []
      const trancheIds = tranchesForDeal.map((tranche) => tranche.trancheId)
      trancheIdsByDeal[dealUuid] = trancheIds
    })
  }

  return {
    trancheIdsByDeal,
    isLoading,
    isError,
  }
}

export default useTranchesByDeals
