import {
  FlexBox,
  FlexBoxDirection,
  Table,
  TableCell,
  TableColumn,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/rent-roll/overview/structure-overview/PropertyRentRollStructureOverviewTable.module.css'

const PropertyRentRollStructureOverviewTable = ({
  id,
  columnDefinitions,
  tableData,
  hasPermission,
}) => {
  const columns = (
    <>
      {columnDefinitions.map((columnDefinition) => (
        <TableColumn key={columnDefinition.columnKey} minWidth={columnDefinition.minWidth}>
          {columnDefinition.renderColumnContent()}
        </TableColumn>
      ))}
    </>
  )

  const getTableRowClassNames = (tableRow) => {
    const tableStyle = tableRow.aggregate ? styles.tableGroupRow : styles.tableRow
    const faultyStyle = tableRow.isFaulty ? styles.faultyTableRow : ''
    return `${tableStyle} ${faultyStyle}`
  }

  const rows = (
    <>
      {tableData.map((tableRow) => (
        <TableRow key={tableRow.rowKey} className={getTableRowClassNames(tableRow)}>
          {tableRow.cells.map((cell) => (
            <TableCell key={`${tableRow.rowKey}-${cell.columnKey}`}>{cell.renderCell()}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )

  const noDataText = `${t('components.cards.empty.title')} ${t('components.cards.empty.subtitle')}`

  const noPermissionText = `${t('app.permission-error.title')} ${t(
    'app.permission-error.subtitle',
  )}`

  return (
    <FlexBox className={styles.tableWrapper} direction={FlexBoxDirection.Column}>
      <Table
        id={id}
        className={styles.tableOverflow}
        noDataText={!hasPermission ? noPermissionText : noDataText}
        columns={columns}
      >
        {rows}
      </Table>
    </FlexBox>
  )
}
PropertyRentRollStructureOverviewTable.propTypes = {
  id: PropTypes.string.isRequired,
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string,
      renderColumnContent: PropTypes.func,
    }),
  ).isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      rowKey: PropTypes.string,
      isFaulty: PropTypes.bool,
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          columnKey: PropTypes.string,
          renderColumnContent: PropTypes.func,
        }),
      ),
    }),
  ).isRequired,
  hasPermission: PropTypes.bool.isRequired,
}
export default PropertyRentRollStructureOverviewTable
