import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'
import useDataSourceQueryParams from 'routes/deals/financing/utils/useDataSourceQueryParams'

const REQUEST_RETRIES = 3

const useTranche = (dealId, trancheId) => {
  const { dataSourceQueryKeys, dataSourceQueryParam } = useDataSourceQueryParams()

  return useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/details?${dataSourceQueryParam}`,
      keys: ['deals', dealId, 'tranches', trancheId, ...dataSourceQueryKeys],
      options: {
        enabled: !!trancheId && !!dealId,
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          return failureCount < REQUEST_RETRIES
        },
      },
    }),
  )
}

export default useTranche
