import { z } from 'zod'

const MoneyDTO = z.object({
  number: z.number(),
  currency: z.string(),
})

const AreaMeasurement = z.object({
  value: z.number(),
  measurement_unit: z.string(),
})

const KPIAreaMeasurementDTO = AreaMeasurement.extend({ percent: z.number() })

const expiringLeasesLabels = /** @type {const} */ ([
  '1-Q1',
  '1-Q2',
  '1-Q3',
  '1-Q4',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '10+',
])

const KPIExpiringLeasesDTO = z.object({
  label: z.enum(expiringLeasesLabels),
  start_date: z.string(),
  end_date: z.string().nullable(),
  current_rent_expiring_break: MoneyDTO,
  current_rent_expiring_expiry: MoneyDTO,
  remaining_current_rent_break: MoneyDTO,
  remaining_current_rent_expiry: MoneyDTO,
})

const RentRollBaseKPIs = z.object({
  total_area_surface: AreaMeasurement,
  total_number_of_units: z.number(),
  vacancy_number_of_units: z.number(),
  self_number_of_units: z.number(),
  let_number_of_units: z.number(),
  annualized_contracted_rent: MoneyDTO,
  annualized_contracted_rent_per_uom: MoneyDTO,
  annualized_current_rent: MoneyDTO,
  annualized_current_rent_per_uom: MoneyDTO,
  vacancy_surface: KPIAreaMeasurementDTO,
  self_surface: KPIAreaMeasurementDTO,
  let_surface: KPIAreaMeasurementDTO,
  wault_to_break_in_years: z.number(),
  wault_to_expiry_in_years: z.number(),
  expiring_leases: z.array(KPIExpiringLeasesDTO).length(expiringLeasesLabels.length),
  total_market_rent: MoneyDTO,
  total_market_rent_per_uom: MoneyDTO,
  let_market_rent: MoneyDTO,
  let_market_rent_per_uom: MoneyDTO,
  vacancy_market_rent: MoneyDTO,
  vacancy_market_rent_per_uom: MoneyDTO,
  total_number_of_rental_units: z.number(),
  let_number_of_rental_units: z.number(),
  vacancy_number_of_rental_units: z.number(),
  self_number_of_rental_units: z.number(),
  segment_usage_types: z.array(
    z.object({
      key: z.string(),
      display_name: z.string().nullish(),
    }),
  ),
})

const CityRentRollKPIsDTO = RentRollBaseKPIs.extend({
  city: z.string(),
})

const CountryRentRollKPIsDTO = RentRollBaseKPIs.extend({
  country_code: z.string(),
  country_name: z.string(),
})

const PropertyTypeRentRollKPIsDTO = RentRollBaseKPIs.extend({
  property_type_code: z.string(),
  property_type_name: z.string(),
})

const TenantRentRollKPIsDTO = RentRollBaseKPIs.extend({
  tenant: z.string(),
})

const TenantIndustryRentRollKPIsDTO = RentRollBaseKPIs.extend({
  anonymous_tenant: z.boolean(),
  tenant_industry_sector_id: z.string().nullish(),
  tenant_industry_key_description: z.string().nullish(),
})

const SegmentUsageTypeRentRollKPIsDTO = RentRollBaseKPIs.extend({
  segment_usage_type_code: z.string(),
  segment_usage_type_name: z.string().nullish(),
})

const TegovaRatingRentRollKPIsDTO = RentRollBaseKPIs.extend({
  tegova_rating_code: z.number().nullish(),
})

const RentRollKPIsDTO = ({
  with_tenant_granularity = false,
  with_city_granularity = false,
  with_property_type_granularity = false,
  with_country_granularity = false,
  with_tenant_industry_granularity = false,
  with_segment_usage_type_granularity = false,
  with_tegova_rating_granularity = false,
} = {}) =>
  RentRollBaseKPIs.extend({
    tenant_rent_roll_kpis: with_tenant_granularity ? z.array(TenantRentRollKPIsDTO) : z.null(),
    city_rent_roll_kpis: with_city_granularity ? z.array(CityRentRollKPIsDTO) : z.null(),
    property_type_rent_roll_kpis: with_property_type_granularity
      ? z.array(PropertyTypeRentRollKPIsDTO)
      : z.null(),
    country_rent_roll_kpis: with_country_granularity ? z.array(CountryRentRollKPIsDTO) : z.null(),
    tenant_industry_rent_roll_kpis: with_tenant_industry_granularity
      ? z.array(TenantIndustryRentRollKPIsDTO)
      : z.null(),
    segment_usage_type_rent_roll_kpis: with_segment_usage_type_granularity
      ? z.array(SegmentUsageTypeRentRollKPIsDTO)
      : z.null(),
    tegova_rating_rent_roll_kpis: with_tegova_rating_granularity
      ? z.array(TegovaRatingRentRollKPIsDTO)
      : z.null(),
  })

const PropertiesService = {
  MoneyDTO,
  AreaMeasurement,
  KPIAreaMeasurementDTO,
  KPIExpiringLeasesDTO,
  RentRollBaseKPIs,
  CityRentRollKPIsDTO,
  CountryRentRollKPIsDTO,
  PropertyTypeRentRollKPIsDTO,
  TenantRentRollKPIsDTO,
  TenantIndustryRentRollKPIsDTO,
  SegmentUsageTypeRentRollKPIsDTO,
  TegovaRatingRentRollKPIsDTO,
  RentRollKPIsDTO,
}

export default PropertiesService
