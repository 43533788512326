import _, { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  AggregatedAnnualizedCurrentRentCell,
  AggregatedRentalUnitsCell,
  AggregatedTotalAreaCell,
  AggregatedWaultCell,
  AnnualizedCurrentRentCell,
  EmptyCell,
  RentalUnitsCell,
  TenantCell,
  TotalAreaCell,
  WaultCell,
} from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCells'
import { renderAnalyticalTableCell } from 'components/ui/tables/analytical/AnalyticalTableCell'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  usePercentageFormatter,
  useNumberFormatter,
} from 'hooks/i18n/useI18n'

export const ACCESSORS = {
  TENANT: 'tenant.name',
  SEGMENT_NAME: 'tenant.segmentName',
  SEGMENT_USAGE_TYPE: 'tenant.usageType',
  RENTAL_UNITS: 'rentalUnits.count.current',
  RENTAL_UNITS_COMPARE: 'rentalUnits.count.compare',
  TOTAL_AREA: 'totalArea.surface.current.value',
  TOTAL_AREA_COMPARE: 'totalArea.surface.compare.value',
  TOTAL_AREA_SHARE: 'totalArea.share.current',
  TOTAL_AREA_SHARE_COMPARE: 'totalArea.share.compare',
  TOTAL_AREA_NO: 'totalArea.number_of_units.current',
  TOTAL_AREA_NO_COMPARE: 'totalArea.number_of_units.compare',
  ANNUALIZED_CURRENT_RENT: 'annualizedCurrentRent.value.current.number',
  ANNUALIZED_CURRENT_RENT_COMPARE: 'annualizedCurrentRent.value.compare.number',
  ANNUALIZED_CURRENT_RENT_PER_UOM: 'annualizedCurrentRent.per_uom.current.number',
  ANNUALIZED_CURRENT_RENT_PER_UOM_COMPARE: 'annualizedCurrentRent.per_uom.compare.number',
  ANNUALIZED_CURRENT_RENT_SHARE: 'annualizedCurrentRent.share.current',
  ANNUALIZED_CURRENT_RENT_SHARE_COMPARE: 'annualizedCurrentRent.share.compare',
  WAULT_TO_EXPIRY: 'wault.expiry.current',
  WAULT_TO_EXPIRY_COMPARE: 'wault.expiry.compare',
  WAULT_TO_BREAK: 'wault.break.current',
  WAULT_TO_BREAK_COMPARE: 'wault.break.compare',
}

export const getSegmentUsageTypeEnums = (segments) =>
  segments.reduce((acc, segment) => {
    if (segment?.usage_type_name) {
      acc[segment.usage_type_name] = segment.usage_type_name
    }
    return acc
  }, {})

export const useRentRollComparisonColumns = (areaMeasureUnitCode, segments) => {
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const formatCurrency = useCustomizableCurrencyFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const formatPercent = usePercentageFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const formatArea = useAreaMeasurementUnitFormatter()

  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const formatNumberCompact = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const formatWault = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const additionalData = {
    t: tComparison,
    formatCurrency,
    formatPercent,
    formatArea,
    formatNumber,
    formatNumberCompact,
    formatWault,
  }

  const additionalDataWithAccessors = (currentAccessor, compareAccessor) => ({
    ...additionalData,
    accessors: {
      current: currentAccessor,
      compare: compareAccessor,
    },
  })

  const defaultDisables = {
    disableDragAndDrop: true,
    disableGlobalFilter: true,
  }

  const getFactoredValue = (accessor, factor) => (row) => (get(row, accessor) ?? 0) * factor || 0

  return [
    {
      id: ACCESSORS.TENANT,
      accessor: ACCESSORS.TENANT,
      minWidth: 250,
      Header: tComparison('table.columns.tenant-segment'),
      filterLabel: tComparison('table.columns.tenant'),
      sortByLabel: tComparison('table.columns.tenant'),
      Cell: renderAnalyticalTableCell(TenantCell, additionalData),
      filterType: 'CONTAINS',
      Aggregated: renderAnalyticalTableCell(TenantCell, additionalData, true),
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      hide: true,
      id: ACCESSORS.TENANT + '_group',
      accessor: ACCESSORS.TENANT,
      Header: tComparison('table.columns.tenant'),
      Cell: renderAnalyticalTableCell(EmptyCell, additionalData),
      Aggregated: renderAnalyticalTableCell(EmptyCell, additionalData, true),
      disableFilters: true,
      disableSortBy: true,
      ...defaultDisables,
    },
    {
      hide: true,
      id: ACCESSORS.SEGMENT_NAME,
      accessor: ACCESSORS.SEGMENT_NAME,
      Header: tComparison('table.columns.segment-name'),
      filterType: 'CONTAINS',
      Cell: renderAnalyticalTableCell(EmptyCell, additionalData),
      Aggregated: renderAnalyticalTableCell(EmptyCell, additionalData, true),
      ...defaultDisables,
    },
    {
      hide: true,
      id: ACCESSORS.SEGMENT_USAGE_TYPE,
      accessor: ACCESSORS.SEGMENT_USAGE_TYPE,
      Header: tComparison('table.columns.segment-usage-type'),
      filterType: 'OF_ENUM_TYPE',
      additionalFilterOptions: {
        enumValues: getSegmentUsageTypeEnums(segments),
      },
      Cell: renderAnalyticalTableCell(EmptyCell, additionalData),
      Aggregated: renderAnalyticalTableCell(EmptyCell, additionalData, true),
      ...defaultDisables,
    },
    {
      id: ACCESSORS.RENTAL_UNITS,
      accessor: ACCESSORS.RENTAL_UNITS,
      minWidth: 250,
      Header: tComparison('table.columns.rental-units'),
      Cell: renderAnalyticalTableCell(RentalUnitsCell, additionalData),
      filterType: 'BETWEEN_NUMBERS',
      Aggregated: renderAnalyticalTableCell(
        AggregatedRentalUnitsCell,
        additionalDataWithAccessors(ACCESSORS.RENTAL_UNITS, ACCESSORS.RENTAL_UNITS_COMPARE),
        true,
      ),
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      id: ACCESSORS.TOTAL_AREA,
      accessor: ACCESSORS.TOTAL_AREA,
      minWidth: 250,
      Header: tComparison('table.columns.total-area'),
      Cell: renderAnalyticalTableCell(TotalAreaCell, additionalData),
      Aggregated: renderAnalyticalTableCell(
        AggregatedTotalAreaCell,
        additionalDataWithAccessors(
          {
            surface: ACCESSORS.TOTAL_AREA,
            share: ACCESSORS.TOTAL_AREA_SHARE,
            number: ACCESSORS.TOTAL_AREA_NO,
          },
          {
            surface: ACCESSORS.TOTAL_AREA_COMPARE,
            share: ACCESSORS.TOTAL_AREA_SHARE_COMPARE,
            number: ACCESSORS.TOTAL_AREA_NO_COMPARE,
          },
        ),
        true,
      ),
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      hide: true,
      id: ACCESSORS.TOTAL_AREA_SHARE,
      accessor: getFactoredValue(ACCESSORS.TOTAL_AREA_SHARE, 100),
      Header: tComparison('table.columns.total-area-share'),
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      hide: true,
      id: ACCESSORS.TOTAL_AREA_NO,
      accessor: ACCESSORS.TOTAL_AREA_NO,
      Header: tComparison('table.columns.total-area-no'),
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      id: ACCESSORS.ANNUALIZED_CURRENT_RENT,
      accessor: ACCESSORS.ANNUALIZED_CURRENT_RENT,
      minWidth: 250,
      Header: tComparison('table.columns.annualized-current-rent'),
      Cell: renderAnalyticalTableCell(AnnualizedCurrentRentCell, additionalData),
      Aggregated: renderAnalyticalTableCell(
        AggregatedAnnualizedCurrentRentCell,
        additionalDataWithAccessors(
          {
            totalArea: ACCESSORS.TOTAL_AREA,
            totalRent: ACCESSORS.ANNUALIZED_CURRENT_RENT,
            perUom: ACCESSORS.ANNUALIZED_CURRENT_RENT_PER_UOM,
            share: ACCESSORS.ANNUALIZED_CURRENT_RENT_SHARE,
          },
          {
            totalArea: ACCESSORS.TOTAL_AREA_COMPARE,
            totalRent: ACCESSORS.ANNUALIZED_CURRENT_RENT_COMPARE,
            perUom: ACCESSORS.ANNUALIZED_CURRENT_RENT_PER_UOM_COMPARE,
            share: ACCESSORS.ANNUALIZED_CURRENT_RENT_SHARE_COMPARE,
          },
        ),
        true,
      ),
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      hide: true,
      id: ACCESSORS.ANNUALIZED_CURRENT_RENT_PER_UOM,
      accessor: ACCESSORS.ANNUALIZED_CURRENT_RENT_PER_UOM,
      Header: tComparison('table.columns.annualized-current-rent-per-uom', {
        uom: formatArea(areaMeasureUnitCode),
      }),
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      hide: true,
      id: ACCESSORS.ANNUALIZED_CURRENT_RENT_SHARE,
      accessor: getFactoredValue(ACCESSORS.ANNUALIZED_CURRENT_RENT_SHARE, 100),
      Header: tComparison('table.columns.annualized-current-rent-share'),
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      id: ACCESSORS.WAULT_TO_EXPIRY,
      accessor: ACCESSORS.WAULT_TO_EXPIRY,
      minWidth: 250,
      Header: tComparison('table.columns.wault-expiry'),
      Cell: renderAnalyticalTableCell(WaultCell, additionalData),
      Aggregated: renderAnalyticalTableCell(
        AggregatedWaultCell,
        additionalDataWithAccessors(
          {
            total: ACCESSORS.ANNUALIZED_CURRENT_RENT,
            waultExpiry: ACCESSORS.WAULT_TO_EXPIRY,
            waultBreak: ACCESSORS.WAULT_TO_BREAK,
          },
          {
            total: ACCESSORS.ANNUALIZED_CURRENT_RENT_COMPARE,
            waultExpiry: ACCESSORS.WAULT_TO_EXPIRY_COMPARE,
            waultBreak: ACCESSORS.WAULT_TO_BREAK_COMPARE,
          },
        ),
      ),
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      hide: true,
      id: ACCESSORS.WAULT_TO_BREAK,
      accessor: ACCESSORS.WAULT_TO_BREAK,
      Header: tComparison('table.cells.wault-break'),
      filterType: 'BETWEEN_NUMBERS',
      disableGroupBy: true,
      ...defaultDisables,
    },
  ]
}
