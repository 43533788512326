import { FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import { isNull, isUndefined } from 'lodash'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AlignedMultiCell,
  TitleAndSubTitleCell,
} from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverviewCells'
import StructureOverviewNoDataAvailableCell from 'components/domains/properties/rent-roll/overview/structure-overview/StructureOverviewNoDataAvailableCell'
import { CELLS_IN_MULTI_COLUMN } from 'components/domains/properties/rent-roll/overview/structure-overview/constants'
import { PERIODS } from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'
import LoadingContent from 'components/ui/content/LoadingContent'
import {
  usePercentageFormatter,
  useNumberFormatter,
  useCustomizableCurrencyFormatter,
} from 'hooks/i18n/useI18n'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'
import useCurrentSegmentKpis from 'hooks/services/properties/kpis/useCurrentSegmentKpis'
import { SEGMENT_RENTAL_INCOME_TABLE_COLUMNS } from 'hooks/services/properties/segments/rental-income/useSegmentRentalIncomeTableColumns'

export const useSegmentRentalIncomeTableRows = (property, segments) => {
  const { NAME_AND_USAGE_TYPE, MARKET_RENT, CONTRACTED_VALUES, CURRENT_VALUES, WAULT } =
    SEGMENT_RENTAL_INCOME_TABLE_COLUMNS
  const MONTHLY_DIVISOR = 12

  const { t: tRentalIncome } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income',
  })

  const selectedCurrency = useSelector(
    (state) => state.properties.commonCurrency.selectedCommonCurrency,
  )
  const selectedPeriod = useSelector((state) => state.properties.segmentsRentalIncome.period)

  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatWault = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const formatPercentDifference = (number) => {
    if (number > 1) return `+${formatPercent(number - 1)}`
    else if (number < 1) return `-${formatPercent(1 - number)}`
    else return `${formatPercent(1 - number)}`
  }

  const getValueState = (number) => {
    if (number > 1) return ValueState.Success
    if (number === 0) return undefined
    if (number < 1) return ValueState.Error
  }

  const {
    isLoading: isLoadingCurrentSegmentKpis,
    isError: isErrorCurrentSegmentKpis,
    data: currentSegmentKpisData,
  } = useCurrentSegmentKpis(
    property?.uuid,
    segments.map((segment) => segment.uuid),
    {
      currency: selectedCurrency,
      areaUnitOfMeasurement: property?.area_measure_unit_code,
    },
  )
  const currentSegmentKpis = currentSegmentKpisData?.kpis
  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpiData,
  } = useCurrentRentRollOverviewPropertyKpis()

  const isLoading = isLoadingPropertyKpis || isLoadingCurrentSegmentKpis

  const isMonthly = selectedPeriod === PERIODS.MONTHLY

  const [faultySegments, setFaultySegments] = useState([])

  useEffect(() => {
    if (isLoading && !isEmpty(faultySegments)) {
      setFaultySegments([])
    }
  }, [faultySegments, isLoading])

  const sumBySegmentAccessor = (valueFn) =>
    segments.reduce((acc, segment) => acc + (valueFn(segment) || 0), 0)

  const weightedSumBySegmentAccessor = (valueFn, weightFn, sumFn) =>
    segments.reduce(
      (acc, segment) => acc + (valueFn(segment) * (weightFn(segment) / sumFn()) || 0),
      0,
    )

  const divideByPeriod = (value) => (isMonthly ? (value || 0) / MONTHLY_DIVISOR : value) || 0

  const multiplyByPeriod = (value) => (isMonthly ? (value || 0) * MONTHLY_DIVISOR : value) || 0

  const findKpi = (segment) => currentSegmentKpis?.find((kpi) => kpi.segment_uuid === segment.uuid)

  const isSegmentContainedInRentRoll = (segment) => {
    const kpi = findKpi(segment)
    return !!kpi && kpi.segment_usage_types?.length > 0
  }

  const findLetArea = (segment) => findKpi(segment)?.let_surface?.value

  const findLetAreaSum = () => sumBySegmentAccessor(findLetArea)

  const findMarketRent = (segment) => divideByPeriod(segment?.market_rent_amount?.number)

  const findTotalMarketRent = () => divideByPeriod(propertyKpiData?.totalMarketRent?.number)

  const calculateOverUnderRented = (segment, value) => {
    const marketRent = findMarketRent(segment)
    if (
      isNull(value) ||
      isUndefined(value) ||
      isNull(marketRent) ||
      isUndefined(marketRent) ||
      marketRent <= 0
    )
      return 0
    return value / marketRent
  }

  const findContractedTotalRent = (segment) =>
    divideByPeriod(findKpi(segment)?.annualized_contracted_rent?.number)

  const findContractedTotalRentSum = () => sumBySegmentAccessor(findContractedTotalRent)

  const findContractedRentPerUOM = (segment) =>
    divideByPeriod(findKpi(segment)?.annualized_contracted_rent_per_uom?.number)

  const findContractedRentPerUOMSum = () =>
    weightedSumBySegmentAccessor(findContractedRentPerUOM, findLetArea, findLetAreaSum)

  const findCurrentTotalRent = (segment) =>
    divideByPeriod(findKpi(segment)?.annualized_current_rent?.number)

  const findCurrentRentPerUOM = (segment) =>
    divideByPeriod(findKpi(segment)?.annualized_current_rent_per_uom?.number)

  const findCurrentOverUnderRented = (segment) =>
    calculateOverUnderRented(segment, findCurrentRentPerUOM(segment))

  const findCurrentRentPerUOMSum = () =>
    weightedSumBySegmentAccessor(findCurrentRentPerUOM, findLetArea, findLetAreaSum)

  const findCurrentTotalRentSum = () => sumBySegmentAccessor(findCurrentTotalRent)

  const findContractedOverUnderRented = (segment) =>
    calculateOverUnderRented(segment, findContractedRentPerUOM(segment))

  const findWAULT = (segment) => multiplyByPeriod(findKpi(segment)?.wault_to_break_in_years)

  const findWAULTWeightedSum = () =>
    weightedSumBySegmentAccessor(findWAULT, findCurrentTotalRent, findCurrentTotalRentSum)

  const formattedMoney = (number) =>
    !isNull(number) && !isUndefined(number)
      ? `${formatCurrency(number, selectedCurrency, {
          currencyDisplay: 'code',
        })}`
      : null

  const setFaultySegmentsInitially = (segment) => {
    if (!isLoading && segment && !faultySegments.includes(segment.uuid)) {
      setFaultySegments((prev) => [...prev, segment.uuid])
    }
  }

  const formatOrPlaceholder = ({
    formatter,
    kpiMethod,
    segment,
    isPublishedSegment,
    noDataAlignment = FlexBoxJustifyContent.Center,
  }) => {
    const value = kpiMethod(segment)
    if (value) {
      return formatter(value)
    } else {
      setFaultySegmentsInitially(segment)
      return isPublishedSegment ? (
        <StructureOverviewNoDataAvailableCell times={1} alignment={noDataAlignment} />
      ) : (
        <></>
      )
    }
  }

  const segmentRows = segments.map((segment) => ({
    rowKey: segment.uuid,
    isFaulty: faultySegments.includes(segment.uuid),
    cells: [
      {
        columnKey: NAME_AND_USAGE_TYPE,
        renderCell: () => (
          <TitleAndSubTitleCell title={segment.name} subTitle={segment.usage_type_name} />
        ),
      },
      {
        columnKey: MARKET_RENT,
        renderCell: () => (
          <AlignedMultiCell
            alignment={FlexBoxJustifyContent.End}
            values={[
              formatOrPlaceholder({
                formatter: formattedMoney,
                kpiMethod: findMarketRent,
                segment: segment,
                isPublishedSegment: isSegmentContainedInRentRoll(segment),
                noDataAlignment: FlexBoxJustifyContent.End,
              }),
            ]}
          />
        ),
      },
      {
        columnKey: CONTRACTED_VALUES,
        renderCell: () => (
          <LoadingContent
            contentKey={CONTRACTED_VALUES}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis && !isSegmentContainedInRentRoll(segment)}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findContractedTotalRent,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findContractedRentPerUOM,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formatPercentDifference,
                  kpiMethod: findContractedOverUnderRented,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
              valueStates={[
                undefined,
                undefined,
                getValueState(findContractedOverUnderRented(segment)),
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: CURRENT_VALUES,
        renderCell: () => (
          <LoadingContent
            contentKey={CURRENT_VALUES}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis && !isSegmentContainedInRentRoll(segment)}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findCurrentTotalRent,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findCurrentRentPerUOM,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formatPercentDifference,
                  kpiMethod: findCurrentOverUnderRented,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
              valueStates={[
                undefined,
                undefined,
                getValueState(findCurrentOverUnderRented(segment)),
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: WAULT,
        renderCell: () => (
          <LoadingContent
            contentKey={WAULT}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis && !isSegmentContainedInRentRoll(segment)}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={1}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formatWault,
                  kpiMethod: findWAULT,
                  segment: segment,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
            />
          </LoadingContent>
        ),
      },
    ],
  }))

  const totalRow = {
    rowKey: 'total',
    aggregate: true,
    cells: [
      {
        columnKey: NAME_AND_USAGE_TYPE,
        renderCell: () => <b>{tRentalIncome('table.columns.total')}</b>,
      },
      {
        columnKey: MARKET_RENT,
        renderCell: () => (
          <LoadingContent
            contentKey={MARKET_RENT}
            isLoading={isLoadingPropertyKpis}
            isError={isErrorPropertyKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={1}
                alignment={FlexBoxJustifyContent.Start}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findTotalMarketRent,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: CONTRACTED_VALUES,
        renderCell: () => (
          <LoadingContent
            contentKey={CONTRACTED_VALUES}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                showIteration={[true, true, false]}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findContractedTotalRentSum,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findContractedRentPerUOMSum,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                '',
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: CURRENT_VALUES,
        renderCell: () => (
          <LoadingContent
            contentKey={CURRENT_VALUES}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                showIteration={[true, true, false]}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findCurrentTotalRentSum,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findCurrentRentPerUOMSum,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                '',
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: WAULT,
        renderCell: () => (
          <LoadingContent
            contentKey={WAULT}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={1}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formatWault,
                  kpiMethod: findWAULTWeightedSum,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
            />
          </LoadingContent>
        ),
      },
    ],
  }

  const publishedSegments = currentSegmentKpis?.filter((kpi) => !isEmpty(kpi.segment_usage_types))

  if (segmentRows?.length > 0) {
    return {
      isError: isErrorCurrentSegmentKpis || isErrorPropertyKpis || faultySegments.length > 0,
      isErrorAllSegmentsPublished: publishedSegments?.length !== segmentRows.length && !isLoading,
      data: segmentRows.concat([totalRow]),
    }
  } else {
    return {
      isError: false,
      isErrorAllSegmentsPublished: false,
      data: [],
    }
  }
}
