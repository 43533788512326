import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const useDataSourceQueryParams = () => {
  const [searchParams] = useSearchParams()

  return useMemo(() => {
    const isExistingBusinessViewSet =
      searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS
    return {
      dataSourceQueryParam: `dataSource=${
        isExistingBusinessViewSet ? DATA_SOURCES.EXISTING_BUSINESS : DATA_SOURCES.NEW_BUSINESS
      }`,
      dataSourceQueryKeys: [
        'dataSource',
        isExistingBusinessViewSet ? DATA_SOURCES.EXISTING_BUSINESS : DATA_SOURCES.NEW_BUSINESS,
      ],
      isExistingBusinessViewSet,
    }
  }, [searchParams])
}

export default useDataSourceQueryParams
