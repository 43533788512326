import get from 'lodash.get'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import useMultiPropertiesKpis from 'hooks/services/properties/kpis/useMultiPropertiesKpis'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import { useProperty } from 'hooks/services/properties/useProperty'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const MAX_DISPLAYED_TENANTS = 3

const sortTenantsKpisByRelevance = (a, b) => {
  const comparisonValue =
    (b.annualizedCurrentRent?.number ?? 0) - (a.annualizedCurrentRent?.number ?? 0) ||
    (b.totalAreaSurface?.value ?? 0) - (a.totalAreaSurface?.value ?? 0) ||
    (a.tenant ?? '').localeCompare(b.tenant ?? '')

  return comparisonValue
}

const sumBy = (array, getValue) => {
  const sum = array.reduce((sumValue, item) => sumValue + getValue(item), 0)
  return !Number.isNaN(sum) ? sum : undefined
}

export const sumByField = (array, fieldName) => sumBy(array, (item) => get(item, fieldName, 0))

export const weightedAvgByFields = (array, valueFieldName, weightFactorFieldName) => {
  const weightFactorTotal = sumBy(array, (item) => get(item, weightFactorFieldName))
  return sumBy(
    array,
    (item) => (get(item, valueFieldName, 0) * get(item, weightFactorFieldName)) / weightFactorTotal,
  )
}

const useTenancyOverview = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isFetchingDealUuid,
    isError: isErrorDealUuid,
  } = useDealUuidByTileCode({ dealUuid, tileCode })

  const {
    data: dealProperties,
    isFetching: isFetchingDealProperties,
    isError: isErrorDealProperties,
  } = useDealProperties({ dealUuid: dealUuidByTileCode })

  const dealPropertyUuids = (dealProperties?.dealProperties ?? []).map(
    ({ propertyUuid }) => propertyUuid,
  )

  const dealHasProperties = dealPropertyUuids.length > 0
  const dealHasMultiProperties = dealPropertyUuids.length > 1
  const dealHasSingleProperty = dealPropertyUuids.length === 1

  const {
    data: property,
    isFetching: isFetchingProperty,
    isError: isErrorProperty,
  } = useProperty(dealPropertyUuids[0], true, {
    enabled: dealHasSingleProperty,
  })

  const {
    data: multiPropertyView,
    isFetching: isFetchingMultiPropertyView,
    isError: isErrorMultiPropertyView,
  } = useMultiPropertyViewByPropertyUuids(dealPropertyUuids, {
    enabled: dealHasMultiProperties,
  })

  const {
    data: multiPropertyKpis,
    isFetching: isFetchingMultiPropertyKpis,
    isError: isErrorMultiPropertyKpis,
  } = useCurrentMultiPropertyKpis(
    dealPropertyUuids,
    { withTenantGranularity: true },
    { enabled: dealHasProperties },
  )
  const {
    data: multiPropertiesKpis,
    isFetching: isFetchingPropertyKpis,
    isError: isErrorPropertyKpis,
  } = useCamelizedResponse(
    useMultiPropertiesKpis(
      dealPropertyUuids,
      { withTenantGranularity: true },
      { enabled: dealHasProperties },
    ),
  )
  const individualPropertyKpis = useMemo(
    () => multiPropertiesKpis?.map(({ keyDateToRentRollKpis }) => keyDateToRentRollKpis?.[0]) ?? [],
    [multiPropertiesKpis],
  )

  const dealHasKpis = !!multiPropertyKpis

  const tenantRentRollKpis = useMemo(
    () => multiPropertyKpis?.tenantRentRollKpis ?? [],
    [multiPropertyKpis],
  )

  const tenantKpisSortedByRelevance = useMemo(
    () => [...tenantRentRollKpis].sort(sortTenantsKpisByRelevance),
    [tenantRentRollKpis],
  )

  const mostRelevantTenantsKpis = tenantKpisSortedByRelevance.slice(0, MAX_DISPLAYED_TENANTS)
  const otherTenantsKpis = tenantKpisSortedByRelevance.slice(MAX_DISPLAYED_TENANTS)

  const tenantsIds = useMemo(
    () => mostRelevantTenantsKpis.map(({ tenant }) => tenant).filter((tenant) => !!tenant),
    [mostRelevantTenantsKpis],
  )

  const dealHasNamedTenants = tenantsIds.length > 0

  const {
    data: businessPartners,
    isFetching: isFetchingBusinessPartners,
    isError: isErrorBusinessPartners,
  } = useBusinessPartnerMiniByIds(tenantsIds)

  const mostRelevantTenantKpisWithName = useMemo(
    () =>
      mostRelevantTenantsKpis.map((tenantKpis) => ({
        ...tenantKpis,
        fullName:
          (businessPartners?.businessPartnerMinis ?? []).find((bp) => bp.id === tenantKpis.tenant)
            ?.fullName ?? '',
      })),
    [businessPartners, mostRelevantTenantsKpis],
  )

  const keyDate = multiPropertyKpis?.keyDate

  const getWaultDate = useCallback(
    (waultInYears) => {
      if (!keyDate || typeof waultInYears !== 'number') {
        return undefined
      }
      // extracted { years: waultInYears } into own variable to satisfy IntelliJ
      const years = { years: waultInYears }
      return DateTime.fromISO(keyDate).plus(years).toISODate()
    },
    [keyDate],
  )

  const getWeightedWaultDateByTenant = useCallback(
    (tenantId, waultFieldName) => {
      const relevantPropertyKpis = individualPropertyKpis.filter(({ kpis }) =>
        kpis.tenantRentRollKpis.some(({ tenant }) => tenant === tenantId),
      )
      const tenantKpis = relevantPropertyKpis?.map((kpi) => ({
        ...kpi,
        kpis: {
          ...(kpi?.kpis?.tenantRentRollKpis?.find(({ tenant }) => tenant === tenantId) ?? {}),
        },
      }))

      if (!tenantKpis?.length) {
        return undefined
      }
      const weightFactorFieldName = 'kpis.annualizedCurrentRent.number'
      const weightFactorTotal = sumBy(tenantKpis, (item) => get(item, weightFactorFieldName))
      if (!weightFactorTotal) {
        return undefined
      }
      const dateMillis = sumBy(tenantKpis, (item) => {
        const waultInYears = { years: get(item, `kpis.${waultFieldName}`, 0) }
        const waultDateMillis = DateTime.fromISO(item.keyDate).plus(waultInYears).toMillis()
        return (waultDateMillis * get(item, weightFactorFieldName)) / weightFactorTotal
      })
      return isNaN(dateMillis) ? undefined : DateTime.fromMillis(dateMillis).toISODate()
    },
    [individualPropertyKpis],
  )

  const getRentalUnitsCount = (rentRollKpi) => {
    const rentalUnitsCount =
      get(rentRollKpi, 'letNumberOfRentalUnits') + get(rentRollKpi, 'selfNumberOfRentalUnits')
    return Number.isFinite(rentalUnitsCount) ? rentalUnitsCount : undefined
  }

  const getTenantShare = useCallback(
    (rentRollKpi, fieldName, totalValueFieldName) => {
      const totalValue = get(multiPropertyKpis, totalValueFieldName || fieldName)
      const tenantValue = get(rentRollKpi, fieldName)
      if (tenantValue === undefined || !(totalValue > 0)) return undefined
      return tenantValue / totalValue
    },
    [multiPropertyKpis],
  )

  const getTenantAnnualizedCurrentRentShare = (rentRollKpi) =>
    getTenantShare(rentRollKpi, 'annualizedCurrentRent.number')
  const getTenantTotalMarketRentShare = (rentRollKpi) =>
    getTenantShare(rentRollKpi, 'totalMarketRent.number')
  const getTenantTotalAreaSurfaceShare = (rentRollKpi) =>
    getTenantShare(rentRollKpi, 'totalAreaSurface.value')

  const currency = get(multiPropertyKpis, 'annualizedCurrentRent.currency')
  const areaUnit = get(multiPropertyKpis, 'totalAreaSurface.measurementUnit')

  const topTenants = mostRelevantTenantKpisWithName.map((tenantKpi) => {
    const waultToBreakInYears = get(tenantKpi, 'waultToBreakInYears')
    const waultToExpiryInYears = get(tenantKpi, 'waultToExpiryInYears')

    return {
      tenant: {
        fullName: tenantKpi.fullName,
        id: tenantKpi.tenant,
      },
      rentalUnitsCount: getRentalUnitsCount(tenantKpi),
      usageTypes: get(tenantKpi, 'segmentUsageTypes', []).map(({ displayName }) => displayName),
      areaSurface: get(tenantKpi, 'totalAreaSurface.value'),
      areaSurfaceShare: getTenantTotalAreaSurfaceShare(tenantKpi),
      numberOfUnits: get(tenantKpi, 'totalNumberOfUnits'),
      annualizedCurrentRent: get(tenantKpi, 'annualizedCurrentRent.number'),
      annualizedCurrentRentPerUom: get(tenantKpi, 'annualizedCurrentRentPerUom.number'),
      annualizedCurrentRentShare: getTenantAnnualizedCurrentRentShare(tenantKpi),
      marketRent: get(tenantKpi, 'totalMarketRent.number'),
      marketRentPerUom: get(tenantKpi, 'totalMarketRentPerUom.number'),
      marketRentShare: getTenantTotalMarketRentShare(tenantKpi),
      waultToBreakInYears,
      waultToBreakDate: getWeightedWaultDateByTenant(tenantKpi.tenant, 'waultToBreakInYears'),
      waultToExpiryInYears,
      waultToExpiryDate: getWeightedWaultDateByTenant(tenantKpi.tenant, 'waultToExpiryInYears'),
    }
  })

  const aggregateTenants = (tenants, { minRequiredTenants = 1 } = {}) => {
    if (!tenants || tenants.length < minRequiredTenants) {
      return undefined
    }

    const sumAnnualizedCurrentRent = sumByField(tenants, 'annualizedCurrentRent.number')
    const sumTotalAreaSurface = sumByField(tenants, 'totalAreaSurface.value')
    const sumTotalMarketRent = sumByField(tenants, 'totalMarketRent.number')
    const weightedAvgWaultToBreakInYears = weightedAvgByFields(
      tenants,
      'waultToBreakInYears',
      'annualizedCurrentRent.number',
    )
    const weightedAvgWaultToExpiryInYears = weightedAvgByFields(
      tenants,
      'waultToExpiryInYears',
      'annualizedCurrentRent.number',
    )

    return {
      rentalUnitsCount: sumBy(tenants, getRentalUnitsCount),
      areaSurface: sumTotalAreaSurface,
      areaSurfaceShare: sumBy(tenants, getTenantTotalAreaSurfaceShare),
      numberOfUnits: sumByField(tenants, 'totalNumberOfUnits'),
      annualizedCurrentRent: sumAnnualizedCurrentRent,
      annualizedCurrentRentPerUom: sumAnnualizedCurrentRent / sumTotalAreaSurface,
      annualizedCurrentRentShare: sumBy(tenants, getTenantAnnualizedCurrentRentShare),
      marketRent: sumTotalMarketRent,
      marketRentPerUom: sumTotalMarketRent / sumTotalAreaSurface,
      marketRentShare: sumBy(tenants, getTenantTotalMarketRentShare),
      waultToBreakInYears: weightedAvgWaultToBreakInYears,
      waultToBreakDate: getWaultDate(weightedAvgWaultToBreakInYears),
      waultToExpiryInYears: weightedAvgWaultToExpiryInYears,
      waultToExpiryDate: getWaultDate(weightedAvgWaultToExpiryInYears),
    }
  }

  const topTenantsSubtotal = aggregateTenants(mostRelevantTenantsKpis, { minRequiredTenants: 2 })

  const otherTenants = aggregateTenants(otherTenantsKpis)

  const otherTenantsCount = otherTenantsKpis.length

  const vacancy = useMemo(
    () => ({
      rentalUnitsCount: get(multiPropertyKpis, 'vacancyNumberOfRentalUnits'),
      areaSurface: get(multiPropertyKpis, 'vacancySurface.value'),
      areaSurfaceShare: get(multiPropertyKpis, 'vacancySurface.percent'),
      numberOfUnits: get(multiPropertyKpis, 'vacancyNumberOfUnits'),
      marketRent: get(multiPropertyKpis, 'vacancyMarketRent.number'),
      marketRentPerUom: get(multiPropertyKpis, 'vacancyMarketRentPerUom.number'),
      marketRentShare: getTenantShare(
        multiPropertyKpis,
        'vacancyMarketRent.number',
        'totalMarketRent.number',
      ),
    }),
    [getTenantShare, multiPropertyKpis],
  )

  const total = useMemo(() => {
    const waultToBreakInYears = get(multiPropertyKpis, 'waultToBreakInYears')
    const waultToExpiryInYears = get(multiPropertyKpis, 'waultToExpiryInYears')
    const marketRent = get(multiPropertyKpis, 'totalMarketRent.number')

    return {
      rentalUnitsCount: get(multiPropertyKpis, 'totalNumberOfRentalUnits'),
      areaSurface: get(multiPropertyKpis, 'totalAreaSurface.value'),
      areaSurfaceShare: 1,
      numberOfUnits: get(multiPropertyKpis, 'totalNumberOfUnits'),
      annualizedCurrentRent: get(multiPropertyKpis, 'annualizedCurrentRent.number'),
      annualizedCurrentRentPerUom: get(multiPropertyKpis, 'annualizedCurrentRentPerUom.number'),
      annualizedCurrentRentShare: 1,
      marketRent,
      marketRentPerUom: get(multiPropertyKpis, 'totalMarketRentPerUom.number'),
      marketRentShare: marketRent > 0 ? 1 : undefined,
      waultToBreakInYears,
      waultToBreakDate: getWaultDate(waultToBreakInYears),
      waultToExpiryInYears,
      waultToExpiryDate: getWaultDate(waultToExpiryInYears),
    }
  }, [getWaultDate, multiPropertyKpis])

  const tenancyOverview = useMemo(() => {
    if (!dealHasKpis) {
      return undefined
    }

    return {
      keyDate,
      topTenants,
      topTenantsSubtotal,
      otherTenants,
      otherTenantsCount,
      vacancy,
      total,
      currency,
      areaUnit,
    }
  }, [
    dealHasKpis,
    keyDate,
    topTenants,
    topTenantsSubtotal,
    otherTenants,
    otherTenantsCount,
    vacancy,
    total,
    currency,
    areaUnit,
  ])

  const sourcePath = useMemo(() => {
    const multiPropertyViewUuid = multiPropertyView?.data?.uuid
    if (dealHasMultiProperties && multiPropertyViewUuid) {
      return `/properties/portfolio/rent-roll?portfolio-view-id=${multiPropertyViewUuid}`
    }

    const propertyId = property?.id
    if (dealHasSingleProperty && propertyId) {
      return `/properties/${propertyId}/rent-roll`
    }

    return null
  }, [dealHasMultiProperties, dealHasSingleProperty, multiPropertyView?.data?.uuid, property?.id])

  const isLoading =
    isFetchingDealUuid ||
    isFetchingDealProperties ||
    isFetchingMultiPropertyKpis ||
    isFetchingBusinessPartners ||
    isFetchingProperty ||
    isFetchingMultiPropertyView ||
    isFetchingPropertyKpis

  const isError =
    isErrorDealUuid ||
    isErrorDealProperties ||
    (dealHasProperties && isErrorMultiPropertyKpis) ||
    (dealHasNamedTenants && isErrorBusinessPartners) ||
    (dealHasSingleProperty && isErrorProperty) ||
    (dealHasMultiProperties && isErrorMultiPropertyView) ||
    (dealHasProperties && isErrorPropertyKpis)

  return useMemo(() => {
    const data = {
      tenancyOverview,
      sourceRender: sourcePath && {
        path: sourcePath,
      },
    }

    return {
      isError,
      isLoading,
      data: !(isError || isLoading) ? data : undefined,
    }
  }, [isError, isLoading, sourcePath, tenancyOverview])
}

export default useTenancyOverview
