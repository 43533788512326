import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ObjectStatus,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverviewCells.module.css'
import { HYPHEN_PLACEHOLDER } from 'components/domains/properties/rent-roll/overview/structure-overview/constants'

export const TitleAndSubTitleCell = ({ title, subTitle }) => (
  <FlexBox className={styles.tableCellPadding} direction={FlexBoxDirection.Column}>
    <b>{title ?? HYPHEN_PLACEHOLDER}</b>
    <span className={styles.tableCellSubTitle}>{subTitle || HYPHEN_PLACEHOLDER}</span>
  </FlexBox>
)
TitleAndSubTitleCell.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
}

export const AlignedMultiCell = ({
  alignment = FlexBoxJustifyContent.Center,
  values = [],
  valueStates = [],
}) => {
  const calculatedWidthPercentage = `${100 / values.length}%`
  return (
    <FlexBox className={styles.tableCellPadding} direction={FlexBoxDirection.Row}>
      {values?.map((value, index) => (
        <FlexBox
          key={index}
          style={{
            width: calculatedWidthPercentage,
          }}
          direction={FlexBoxDirection.Row}
          justifyContent={alignment ?? FlexBoxJustifyContent.Center}
          alignItems={FlexBoxAlignItems.Center}
        >
          {valueStates[index] ? (
            <ObjectStatus state={valueStates[index]}>
              <span className={styles.tableCellBoldText}>{value ?? HYPHEN_PLACEHOLDER}</span>
            </ObjectStatus>
          ) : (
            <span>{value ?? HYPHEN_PLACEHOLDER}</span>
          )}
        </FlexBox>
      ))}
    </FlexBox>
  )
}
AlignedMultiCell.propTypes = {
  alignment: PropTypes.string,
  values: PropTypes.array,
  valueStates: PropTypes.array,
}
