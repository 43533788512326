import { Button, ButtonDesign, Select, Option } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsHeaderActions.module.css'
import { PERIODS } from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'

const PropertyRentRollSegmentsHeaderActions = ({
  isMultiProperty,
  inEditMode,
  period,
  onPeriodChange,
  onAdd,
  onDelete,
  selectedSegments,
}) => {
  const { t: tSegments } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.segments',
  })
  if (!inEditMode) {
    return (
      <div>
        <Select onChange={(e) => onPeriodChange(e?.detail?.selectedOption?.dataset?.value)}>
          <Option
            key={PERIODS.ANNUALLY}
            data-value={PERIODS.ANNUALLY}
            selected={period === PERIODS.ANNUALLY}
          >
            {tSegments(PERIODS.ANNUALLY)}
          </Option>
          <Option
            key={PERIODS.MONTHLY}
            data-value={PERIODS.MONTHLY}
            selected={period === PERIODS.MONTHLY}
          >
            {tSegments(PERIODS.MONTHLY)}
          </Option>
        </Select>
      </div>
    )
  }

  return (
    <div>
      {!isMultiProperty && (
        <Button
          id={'segment-table-add-action'}
          className={styles.marginLeft}
          onClick={onAdd}
          design={ButtonDesign.Transparent}
        >
          {t('buttons.add')}
        </Button>
      )}
      <Button
        id={'segment-table-delete-action'}
        disabled={selectedSegments.length < 1}
        className={styles.marginLeft}
        onClick={() => {
          if (selectedSegments.length < 1) return
          onDelete()
        }}
        design={ButtonDesign.Transparent}
      >
        {t('buttons.delete')}
      </Button>
    </div>
  )
}
PropertyRentRollSegmentsHeaderActions.propTypes = {
  isMultiProperty: PropTypes.bool,
  inEditMode: PropTypes.bool,
  period: PropTypes.string,
  onPeriodChange: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  selectedSegments: PropTypes.array,
}

export default PropertyRentRollSegmentsHeaderActions
