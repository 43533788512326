import i18n from 'i18next'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const EURO_SYMBOL = '€'
export const EURO_CODE = 'EUR'
export const CURRENCY_SYMBOL_PLACEHOLDER = '?'
export const CURRENCY_CODE_PLACEHOLDER = 'N/A'

const DEFAULT_LOCALE = 'en-US'
const CACHE = {}

const getCurrencyIntlNumberFormatOrDefault = ({
  currency,
  locales,
  specificOptions = {},
  optionOverrides = {},
}) => {
  const createFormatter = (currencyValue) =>
    new Intl.NumberFormat(locales, {
      ...specificOptions,
      currency: currencyValue,
      ...optionOverrides,
    }).format

  let isPotentiallyValidCurrency = !!currency
  let format

  try {
    format = createFormatter(currency || EURO_CODE)
  } catch (error) {
    isPotentiallyValidCurrency = false
    format = createFormatter(EURO_CODE)
  }

  return {
    isPotentiallyValidCurrency,
    format,
  }
}

export const useLanguage = () => {
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => {
    i18n.on('languageChanged', setLanguage)
    return () => i18n.off('languageChanged')
  }, [])

  return language || DEFAULT_LOCALE
}

export const useFormatterCache = (options, loader) => {
  const language = useLanguage()
  const locales = [language, DEFAULT_LOCALE]
  const optionsKey = Object.keys(options)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => `${key}-${options[key]}`)
    .join('-')
  const cacheKey = locales.join('-') + optionsKey
  if (!CACHE[cacheKey]) {
    CACHE[cacheKey] = loader(locales, options)
  }
  return CACHE[cacheKey]
}

export const useBooleanToTextFormatter = () => {
  const { t } = useTranslation()
  return useCallback(
    (value) => {
      if (typeof value !== 'boolean') return t('formatters.boolean.empty-value')
      const key = value ? 'yes' : 'no'
      return t(`formatters.boolean.${key}`)
    },
    [t],
  )
}

const isNumber = (value) => typeof value === 'number' && !isNaN(value)
const oneThousand = 1000
const oneMillion = 1000000
const isNumberBetween4And6Digits = (value) =>
  isNumber(value) && value >= oneThousand && value < oneMillion

const isGermanLanguage = (locales) => locales[0] === 'de-DE'
const isUSLocale = (locale) => locale === 'en-US'

/**
 * Helper function that returns the dateString format pattern for the given formatter.
 */
export const getShortDatePattern = (formatter, locale) => {
  const separator = ' '
  const partToPattern = (part) => {
    switch (part.type) {
      case 'second':
        return 'ss'
      case 'minute':
        return 'mm'
      case 'hour':
        return isUSLocale(locale) ? 'hh' : 'HH'
      case 'day':
        return 'dd'
      case 'dayPeriod':
        return 'a'
      case 'month':
        return 'MM'
      case 'year':
        return 'yyyy'
      case 'literal':
        return part.value.trim() ? part.value : separator
      default:
        return ''
    }
  }

  return formatter.formatToParts(new Date()).map(partToPattern).join('')
}

/**
 * Exposes useful properties for parsing date-strings between ISO and locale specific format.
 * For optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {*} options Object containing Intl.DateTimeFormat options.
 * @returns
 * * format: Function that takes a dateString in ISO format and returns the string in locale specific format.
 * * parse: Function that takes a dateString in locale specific format and the localePattern and returns the string in ISO format.
 * * localePattern: DateString pattern for the given locale.
 */
export const useShortDateFormatter = ({
  year = 'numeric',
  month = '2-digit',
  day = '2-digit',
  ...options
} = {}) =>
  useFormatterCache(
    {
      year,
      month,
      day,
      ...options,
    },
    (locales, specificOptions) => {
      const formatter = Intl.DateTimeFormat(locales, specificOptions)
      const validIsoStringWithDate = (isoString) => {
        const regex = /^\d{4}-\d{2}-\d{2}/
        return regex.test(isoString)
      }

      return {
        format: (isoString) => {
          if (validIsoStringWithDate(isoString)) {
            const dateTime = DateTime.fromISO(isoString).setLocale(locales[0])
            return dateTime.isValid ? dateTime.toLocaleString(specificOptions) : isoString
          }
          return isoString
        },
        parse: (strValue, localePattern) => {
          if (!strValue || !localePattern) {
            return null
          }
          const dateTime = DateTime.fromFormat(strValue, localePattern)
          return dateTime.toISODate()
        },
        localePattern: getShortDatePattern(formatter, locales[0]),
      }
    },
  )

/**
 *
 * @returns {Object} Returns an object containing the decimal and thousands separators.
 */
export const useSeparators = () => {
  const lang = useLanguage()

  const separators = useMemo(() => {
    const testNumberWithDecimalSeparator = 1.1
    const testNumberWithThousandsSeparator = 1000

    const numberWithDecimalSeparator = new Intl.NumberFormat(lang).format(
      testNumberWithDecimalSeparator,
    )
    const decimalSeparator = numberWithDecimalSeparator.substring(1, 2)

    const numberWithThousandsSeparator = new Intl.NumberFormat(lang).format(
      testNumberWithThousandsSeparator,
    )
    const thousandsSeparator = numberWithThousandsSeparator.substring(1, 2)

    return { decimalSeparator, thousandsSeparator }
  }, [lang])

  return separators
}

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export const useNumberFormatter = ({
  maximumFractionDigits = 2,
  useGrouping = true,
  ...options
} = {}) =>
  useFormatterCache(
    {
      ...options,
      maximumFractionDigits,
      useGrouping,
    },
    (locales, specificOptions) => {
      const { format } = Intl.NumberFormat(locales, {
        ...specificOptions,
      })
      const { format: germanSpecialFormat } = Intl.NumberFormat(locales, {
        ...specificOptions,
      })
      return (value) => {
        if (!isNumber(value)) {
          return ''
        }
        // Needed since PBB wants to have a dot seperator for four digits numbers even though
        // this is not the german "standard". Instead of writing our own parser, only catch this
        // specific requirement here.
        if (isGermanLanguage(locales) && isNumberBetween4And6Digits(value)) {
          if (options['notation'] === 'compact') {
            return germanSpecialFormat(value / oneThousand) + ' Tsd.'
          }
          return germanSpecialFormat(value)
        }
        return format(value)
      }
    },
  )

export const useFormattedNumberParser = () => {
  const { thousandsSeparator, decimalSeparator } = useSeparators()

  return (stringNumber) => {
    if (typeof stringNumber === 'number') {
      return stringNumber
    }
    if (typeof stringNumber !== 'string') {
      return null
    }
    if (stringNumber.trim().length < 1) {
      return null
    }
    return parseFloat(
      stringNumber
        .replace(new RegExp('\\' + thousandsSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.'),
    )
  }
}

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export const useIntFormatter = ({ ...options } = {}) =>
  useFormatterCache(
    {
      ...options,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    (locales, specificOptions) => {
      const { format } = Intl.NumberFormat(locales, specificOptions)
      return (value) => (isNumber(value) ? format(value) : '')
    },
  )

/**
 * Helper-method to place the minus before the number instead of before the currency
 * I.e. EUR -8.000,00 instead of -EUR 8.000,00 for english formatting
 * @param parsedString
 * @returns string
 */
const prefixNumberWithMinus = (parsedString) => {
  const minusSignPattern = /-?/
  const digitsPattern = /(-?[\d,.]+)/ // positive or negative values including commas and points
  return parsedString
    .replace(minusSignPattern, '') // removing the minus
    .replace(digitsPattern, `-$1`) // placing the minus at desired position
}

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export const useCurrencyFormatter = ({ currency, maximumFractionDigits = 2, ...options } = {}) =>
  useFormatterCache(
    {
      ...options,
      style: 'currency',
      currency,
      maximumFractionDigits,
    },
    (locales, specificOptions) => {
      // Valid currency codes consist of three letters and might change
      // Therefore it is only tested that the currency is not undefined, null or ''
      const { isPotentiallyValidCurrency, format } = getCurrencyIntlNumberFormatOrDefault({
        currency,
        locales,
        specificOptions,
      })
      return (value) => {
        let parsedString = isNumber(value) ? format(value) : ''
        if (value < 0) parsedString = prefixNumberWithMinus(parsedString)
        return isPotentiallyValidCurrency
          ? parsedString
          : parsedString.replace(EURO_SYMBOL, CURRENCY_SYMBOL_PLACEHOLDER)
      }
    },
  )

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 *
 * Do not use currencySign: 'accounting' without expecting suspicious results.
 * The default which we agreed on looks like the following for negative values: EUR -1.000,00
 */
export const useCustomizableCurrencyFormatter = ({
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  currencyDisplay = 'code',
  ...options
} = {}) =>
  useFormatterCache(
    {
      ...options,
      style: 'currency',
      maximumFractionDigits,
      minimumFractionDigits,
      currencyDisplay,
    },
    (locales, specificOptions) =>
      (value, currency, optionOverrides = {}) => {
        // Valid currency codes consist of three letters and might change
        // Therefore it is only tested that the currency is not undefined, null or ''
        const { isPotentiallyValidCurrency, format } = getCurrencyIntlNumberFormatOrDefault({
          currency,
          locales,
          specificOptions,
          optionOverrides,
        })
        let parsedString = isNumber(value) ? format(value) : ''
        if (value < 0) parsedString = prefixNumberWithMinus(parsedString)
        return isPotentiallyValidCurrency
          ? parsedString
          : parsedString.replace(EURO_CODE, CURRENCY_CODE_PLACEHOLDER)
      },
  )

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export const usePercentageFormatter = ({
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  ...options
} = {}) =>
  useFormatterCache(
    {
      ...options,
      style: 'percent',
      minimumFractionDigits,
      maximumFractionDigits,
    },
    (locales, specificOptions) => {
      const { format } = Intl.NumberFormat(locales, specificOptions)
      return (value) => (isNumber(value) ? format(value) : '')
    },
  )

const UnitDerivationModifier = {
  None: 'none',
  Square: 'square',
}

const useUnitFormatter = ({ unit, ...options }) =>
  useFormatterCache(
    {
      ...options,
      style: 'unit',
      unit: unit,
      unitDisplay: 'short',
    },
    (locales, specificOptions) => Intl.NumberFormat(locales, specificOptions),
  )

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * Translates CPW Units (M2, FT2, ...) into their localized translations. For example:
 * - locale de: m², ft², ...
 * - locale en: sqm, sqft, ...
 * @param {Object} props
 *  @param {String} overwriteLanguage Optional parameter, which determines the language for the unit translation. Default is the selected CWP language.
 *  @param {...*} options Optional overrides for the Intl API
 * @returns {Function} Returns an area unit in its localized translation
 */
export const useAreaMeasurementUnitFormatter = ({ overwriteLanguage, ...options } = {}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'formatters.area-measurement-unit.derivation',
  })
  const { t: tNoPrefix } = useTranslation()
  const cwpAreaMeasurementUnits = {
    ACR: {
      formatter: useUnitFormatter({ ...options, unit: 'acre' }),
      derivationModifier: UnitDerivationModifier.None,
    },
    CM2: {
      formatter: useUnitFormatter({ ...options, unit: 'centimeter' }),
      derivationModifier: UnitDerivationModifier.Square,
    },
    HA: {
      formatter: useUnitFormatter({ ...options, unit: 'hectare' }),
      derivationModifier: UnitDerivationModifier.None,
    },
    '"2': {
      formatter: useUnitFormatter({ ...options, unit: 'inch' }),
      derivationModifier: UnitDerivationModifier.Square,
    },
    FT2: {
      formatter: useUnitFormatter({ ...options, unit: 'foot' }),
      derivationModifier: UnitDerivationModifier.Square,
    },
    // TODO: Gallon per mile is no area measurement, but CMS offers it as area measurement, so it is supported for now. Recheck CMS customization.
    GPM: {
      formatter: useUnitFormatter({ ...options, unit: 'gallon-per-mile' }),
      derivationModifier: UnitDerivationModifier.None,
    },
    KM2: {
      formatter: useUnitFormatter({ ...options, unit: 'kilometer' }),
      derivationModifier: UnitDerivationModifier.Square,
    },
    // TODO: liter per 100 km is no area measurement, but CMS offers it as area measurement, so it is supported for now. Recheck CMS customization.
    LHK: {
      formatter: useUnitFormatter({ ...options, unit: 'liter-per-kilometer' }),
      denominatorFactor: '100',
      derivationModifier: UnitDerivationModifier.None,
    },
    M2: {
      formatter: useUnitFormatter({ ...options, unit: 'meter' }),
      derivationModifier: UnitDerivationModifier.Square,
    },
    MI2: {
      formatter: useUnitFormatter({ ...options, unit: 'mile' }),
      derivationModifier: UnitDerivationModifier.Square,
    },
    MM2: {
      formatter: useUnitFormatter({ ...options, unit: 'millimeter' }),
      derivationModifier: UnitDerivationModifier.Square,
    },
    YD2: {
      formatter: useUnitFormatter({ ...options, unit: 'yard' }),
      derivationModifier: UnitDerivationModifier.Square,
    },
    [tNoPrefix('formatters.area-measurement-unit.pcs.code')]: {
      formatter: {
        formatToParts: () => [
          {
            type: 'unit',
            value: tNoPrefix('formatters.area-measurement-unit.pcs', { lng: overwriteLanguage }),
          },
        ],
      },
      derivationModifier: UnitDerivationModifier.None,
    },
  }
  return (cwpUnit) => {
    if (!cwpAreaMeasurementUnits[cwpUnit]) {
      return cwpUnit ? cwpUnit : ''
    }
    // First, let Intl.Numberformat translate the base unit. For instance for CWP unit "M2" = square meter, the base unit is meter
    // and we translate to "m"
    // We are only interested in the unit, not the number, so we formatToParts with an "undefined" number
    const formatter = cwpAreaMeasurementUnits[cwpUnit].formatter
    const localizedBaseUnit = formatter
      .formatToParts(undefined)
      .find((it) => it.type === 'unit').value

    // Next we derive the area measurement unit from the base unit.
    // For locale 'de' we typically add the suffix "²" after the base unit
    // For locale 'en' we typically add the prefix "sq" before the base unit
    // see translation.json keys "formatters.area-measurement-unit.derivation.*"
    const derivationModifier = cwpAreaMeasurementUnits[cwpUnit].derivationModifier
    let localizedAreaUnit = t(derivationModifier, {
      localizedBaseUnit: localizedBaseUnit,
      lng: overwriteLanguage,
    })
    // Finally deal with a special case with unit-per-other-unit translations
    // This is only necessary to support liter per 100 km: l/km would be handled by the code above,
    // but Intl.Numberformat cannot translate l/100km out of the box.
    // TODO: This can be removed, once we get rid of l/100km, which is no area measurement at all in the first place.
    const denominatorFactor = cwpAreaMeasurementUnits[cwpUnit].denominatorFactor
    if (denominatorFactor) {
      localizedAreaUnit = localizedAreaUnit.replace('/', '/' + denominatorFactor)
    }
    return localizedAreaUnit
  }
}

/**
 * Returns an object containing the three or four lines of a formated address depending on the language
 */
export const useAddressFormatter = () => {
  const language = useLanguage()
  return useCallback(
    ({ country, street, houseNumber, zipCode, city, state }) => {
      const cityCommaAppended = city && state ? `${city},` : city

      switch (language) {
        case 'en-US':
          return {
            firstLine: [houseNumber, street].join(' '),
            secondLine: [cityCommaAppended, state, zipCode].join(' '),
            thirdLine: country,
          }
        case 'en-GB':
          return {
            firstLine: [houseNumber, street].join(' '),
            secondLine: city,
            thirdLine: zipCode,
            fourthLine: country,
          }
        case 'de-DE':
          return {
            firstLine: [street, houseNumber].join(' '),
            secondLine: [zipCode, city].join(' '),
            thirdLine: country,
          }
        default:
          return {
            firstLine: [houseNumber, street].join(' '),
            secondLine: [cityCommaAppended, state, zipCode].join(' '),
            thirdLine: country,
          }
      }
    },
    [language],
  )
}
