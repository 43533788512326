import { Bar, Button, ButtonDesign, Dialog, Icon, Title } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingButton from 'components/ui/button/LoadingButton'
import { useDeleteRentRollWorkingVersion } from 'hooks/services/properties/useDeleteRentRollWorkingVersion'
import '@ui5/webcomponents-icons/dist/AllIcons'

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const PropertyRentRollWorkingVersionDelete = ({ propertyUuids, setIsDeleting, isDeleting }) => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const routeToRentRoll = pathname.replace('/working-version', `${search}#details`)

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const queryClient = useQueryClient()
  const deleteRentRollWorkingVersion = useDeleteRentRollWorkingVersion({
    onSuccess: () => {
      queryClient.removeQueries(['rent-roll-working-version', ...propertyUuids.sort()])
      navigate(routeToRentRoll)
    },
    onSettled: () => {
      setIsDeleting(false)
    },
  })

  const { t } = useTranslation('translation')

  const handleDelete = () => {
    setIsDeleting(true)
    deleteRentRollWorkingVersion.mutate({
      propertyUuids: propertyUuids,
    })
  }

  return (
    <>
      <Button
        id="rent-roll-working-version-open-delete-dialog-button"
        onClick={() => setIsDialogOpen(true)}
        design={ButtonDesign.Transparent}
      >
        {t('buttons.delete')}
      </Button>
      {createPortal(
        <Dialog
          {...(isDeleting && { inert: '' })}
          id="rent-roll-working-version-delete-dialog"
          open={isDialogOpen}
          onAfterClose={() => setIsDialogOpen(false)}
          header={
            <Bar
              startContent={
                <>
                  <Icon name="alert" style={{ color: '#E9730C' }} />
                  <Title level="H4" style={{ fontWeight: 'normal' }}>
                    {t('pages.rent-roll-working-version.dialog-delete.title')}
                  </Title>
                </>
              }
            />
          }
          footer={
            <Bar
              endContent={
                <>
                  <LoadingButton
                    id="rent-roll-working-version-delete-button"
                    design={ButtonDesign.Emphasized}
                    onClick={() => handleDelete()}
                    renderContent={() => t('buttons.delete')}
                    isLoading={isDeleting}
                  />
                  <Button
                    id="rent-roll-working-version-close-delete-dialog-button"
                    onClick={() => setIsDialogOpen(false)}
                    disabled={isDeleting}
                  >
                    {t('buttons.cancel')}
                  </Button>
                </>
              }
            />
          }
        >
          {t('pages.rent-roll-working-version.dialog-delete.question')}
        </Dialog>,
        document.body,
      )}
    </>
  )
}

PropertyRentRollWorkingVersionDelete.propTypes = {
  propertyUuids: PropTypes.arrayOf(PropTypes.string.isRequired),
  setIsDeleting: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
}

export default PropertyRentRollWorkingVersionDelete
