import {
  ObjectPageSection,
  ObjectPageSubSection,
  ResponsiveGridLayout,
} from '@fioneer/ui5-webcomponents-react'
import { isMajorVersion } from 'api/decision-paper/decisionPaperApi'
import SectionHeader from 'components/domains/business-events-and-tasks/decision-paper/SectionHeader'
import DecisionPaperSubsectionAssessmentWrapper from 'components/domains/business-events-and-tasks/decision-paper/assessment/DecisionPaperSubsectionAssessmentWrapper'
import CommentWrapper from 'components/domains/business-events-and-tasks/decision-paper/comments/DecisionPaperSubsectionCommentWrapper'
import TileTypeWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/TileTypeWrapper'
import CWPLayout from 'components/ui/layout/CWPLayout'

const numberOfColumns = 2

const CASH_FLOW_CASE = [
  'SUBSECTION_NEW_BUSINESS_CASHFLOW_ANALYSIS_ADDITIONAL_CASE',
  'SUBSECTION_NEW_BUSINESS_CASHFLOW_ANALYSIS_BASE_CASE',
  'SUBSECTION_NEW_BUSINESS_CASHFLOW_ANALYSIS_STRESS_CASE',
  'SUBSECTION_WV_APPROVAL_BASE_CASE',
  'SUBSECTION_WV_APPROVAL_WV_LIVE_BASE_CASE',
  'SUBSECTION_WV_APPROVAL_STRESS_CASE',
  'SUBSECTION_WV_APPROVAL_WV_LIVE_STRESS_CASE',
  'SUBSECTION_WV_APPROVAL_ADDITIONAL_CASE',
  'SUBSECTION_WV_APPROVAL_WV_LIVE_ADDITIONAL_CASE',
]

const ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES = [
  'DEAL_SELECTION_SUBSECTION',
  'PROPERTY_PORTFOLIO_SUBSECTION',
  'COVENANTS_AND_RATIOS_SUBSECTION',
  'TENANCY_RENT_ROLL',
  'CASH_FLOW_ANALYSIS',
]

const ANNUAL_REVIEW_DEAL_OVERVIEW_CASH_FLOW_CODES = {
  property: ['MULTIPLE_TENANCY_RENT_ROLL_VACANCY_PROJECTION'],
  baseCase: [
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE',
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE',
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_ASSUMPTIONS_BASE_CASE',
  ],
  stressCase: [
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE',
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE',
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_ASSUMPTIONS_STRESS_CASE',
  ],
}

const mapTiles = ({
  keyPrefix,
  tile,
  index,
  currentVersion,
  showConfirmationForLinks,
  synchronizationStatus,
  isEditableInMinorVersion,
  lastAvailableVersion,
  subsectionTilesIds,
}) => {
  const renderedTile = (
    <TileTypeWrapper
      key={`${keyPrefix}-${index}`}
      tile={tile}
      currentVersion={currentVersion}
      showConfirmationForLinks={showConfirmationForLinks}
      synchronizationStatus={synchronizationStatus}
      isEditableInMinorVersion={isEditableInMinorVersion}
      lastAvailableVersion={lastAvailableVersion}
      subsectionTilesIds={subsectionTilesIds}
    />
  )
  if (tile.viewConfig.columnSpan === 2) {
    return (
      <CWPLayout.Full key={`CWP-Layout-Full-${keyPrefix}-${index}`}>{renderedTile}</CWPLayout.Full>
    )
  }
  return (
    <CWPLayout.Half key={`CWP-Layout-Full-${keyPrefix}-${index}`}>{renderedTile}</CWPLayout.Half>
  )
}

const mapSubSection = ({
  subsectionCalculatedId,
  subsectionName,
  currentVersion,
  hideCommentButton,
  subsectionId,
  mappedSubsectionTiles,
  hideAssessment,
  assessmentTileId,
  isEditableInMinorVersion,
  lastAvailableVersion,
}) => (
  <ObjectPageSubSection
    key={subsectionCalculatedId}
    id={subsectionCalculatedId}
    titleText={subsectionName}
    actions={[
      <CommentWrapper
        key="commentWrapper"
        version={currentVersion}
        hideCommentButton={hideCommentButton}
        subsectionId={subsectionId}
      />,
    ]}
  >
    <CWPLayout>
      {mappedSubsectionTiles}
      {!hideAssessment && (
        <CWPLayout.Full>
          <DecisionPaperSubsectionAssessmentWrapper
            version={currentVersion}
            subsectionId={subsectionId}
            tileId={assessmentTileId}
            isEditableInMinorVersion={isEditableInMinorVersion}
            lastAvailableVersion={lastAvailableVersion}
          />
        </CWPLayout.Full>
      )}
    </CWPLayout>
  </ObjectPageSubSection>
)

// This can't be a "normal" React component since otherwise the ObjectPageSection and ObjectPageSubSection
// are no direct childs of the TabPage and therefore no direct childs of the ui5-ObjectPage which results
// in the Tabs not rendering as expected. Therefore this can't be a React component but "just" a normal function
const renderDecisionPaper = ({
  decisionPaper: { sections, currentVersion, synchronizationStatus, availableVersions },
  hiddenTileIds,
  showConfirmationForLinks = false,
}) => {
  const isTileNotHidden = ({ id }) => !hiddenTileIds?.includes(id)
  const sectionsWithShownSubSectionsAndTiles = sections.filter(
    ({ subsections, tiles }) =>
      tiles.some(isTileNotHidden) ||
      subsections.some(({ tiles: subsectionTiles }) => subsectionTiles.some(isTileNotHidden)),
  )
  const isSingleSectionAndSubsection =
    sectionsWithShownSubSectionsAndTiles.length === 1 &&
    sectionsWithShownSubSectionsAndTiles[0].subsections.filter(({ tiles }) =>
      tiles.some(isTileNotHidden),
    ).length === 1

  return {
    isSingleSectionAndSubsection,
    content: sectionsWithShownSubSectionsAndTiles.map(
      ({ name: sectionName, code: sectionCode, tiles: sectionTiles, subsections }) => {
        const subsectionsWithShownTiles = subsections.filter(({ tiles }) =>
          tiles.some(isTileNotHidden),
        )
        const lastAvailableVersion = availableVersions[availableVersions.length - 1].version
        const tileIds = []
        const calculatedSectionId = `section-${sectionCode}`
        const mappedSectionTiles = sectionTiles.map((tile, index) => {
          tileIds.push(tile.id)
          return mapTiles({
            keyPrefix: calculatedSectionId,
            tile,
            index,
            currentVersion,
            showConfirmationForLinks,
            synchronizationStatus,
            isEditableInMinorVersion: false,
            lastAvailableVersion,
          })
        })
        const mappedSubsections = subsectionsWithShownTiles.map(
          ({
            name: subsectionName,
            code: subsectionCode,
            tiles: subsectionTiles,
            id: subsectionId,
            isEditableInMinorVersion,
            hideAssessment,
          }) => {
            const shownSubsectionTiles = subsectionTiles.filter(isTileNotHidden)
            const mappedSubsectionTiles = shownSubsectionTiles.map((tile, index) => {
              tileIds.push(tile.id)
              let subsectionTilesIds = {}
              const isCashFlowCase = CASH_FLOW_CASE.includes(subsectionCode)
              const isAnnualReviewDealOverviewCase =
                ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.includes(subsectionCode)

              if (isCashFlowCase) {
                const subsectionTilesIdsList = subsectionTiles.map(
                  (cashFlowTile) => cashFlowTile.id,
                )
                subsectionTilesIds = {
                  subsectionCode,
                  subsectionTilesIds: subsectionTilesIdsList,
                }
              } else if (isAnnualReviewDealOverviewCase) {
                // In Annual Review: Deal Overview section, all cash flow tiles need to be handled separately
                const subsectionTilesIdsCashFlowList = () => {
                  if (ANNUAL_REVIEW_DEAL_OVERVIEW_CASH_FLOW_CODES.property.includes(tile.code)) {
                    return [tile.id]
                  } else if (
                    ANNUAL_REVIEW_DEAL_OVERVIEW_CASH_FLOW_CODES.baseCase.includes(tile.code)
                  ) {
                    return subsectionTiles
                      .filter((annualReviewSubsectionTiles) =>
                        ANNUAL_REVIEW_DEAL_OVERVIEW_CASH_FLOW_CODES.baseCase.includes(
                          annualReviewSubsectionTiles.code,
                        ),
                      )
                      .map((cashFlowBaseCaseTile) => cashFlowBaseCaseTile.id)
                  } else if (
                    ANNUAL_REVIEW_DEAL_OVERVIEW_CASH_FLOW_CODES.stressCase.includes(tile.code)
                  ) {
                    return subsectionTiles
                      .filter((annualReviewSubsectionTiles) =>
                        ANNUAL_REVIEW_DEAL_OVERVIEW_CASH_FLOW_CODES.stressCase.includes(
                          annualReviewSubsectionTiles.code,
                        ),
                      )
                      .map((cashFlowBaseCaseTile) => cashFlowBaseCaseTile.id)
                  }
                }

                subsectionTilesIds = {
                  subsectionCode,
                  subsectionTilesIds: subsectionTilesIdsCashFlowList(),
                }
              }

              return mapTiles({
                keyPrefix: `subsection-${subsectionCode}`,
                tile,
                index,
                currentVersion,
                showConfirmationForLinks,
                synchronizationStatus,
                isEditableInMinorVersion,
                lastAvailableVersion,
                subsectionTilesIds,
              })
            })
            const subsectionCalculatedId = `${calculatedSectionId}-subsection-${subsectionCode}`
            const assessmentTileId = `${subsectionId}-assessment`
            const hideCommentButton =
              !isMajorVersion(lastAvailableVersion) && !isEditableInMinorVersion
            tileIds.push(assessmentTileId)
            return mapSubSection({
              subsectionCalculatedId,
              subsectionName,
              currentVersion,
              hideCommentButton,
              subsectionId,
              mappedSubsectionTiles,
              hideAssessment,
              assessmentTileId,
              isEditableInMinorVersion,
              lastAvailableVersion,
            })
          },
        )
        const sectionHeader = <SectionHeader tileIds={tileIds} />
        const sectionTileGrid = (
          <ResponsiveGridLayout
            columnsXL={numberOfColumns}
            columnsL={numberOfColumns}
            columnsM={numberOfColumns}
            columnsS={numberOfColumns}
            columnGap="1rem"
            rowGap="1rem"
          >
            {mappedSectionTiles}
          </ResponsiveGridLayout>
        )
        return isSingleSectionAndSubsection ? (
          <div key={calculatedSectionId}>
            {sectionHeader}
            {sectionTileGrid}
            {mappedSubsections}
          </div>
        ) : (
          <ObjectPageSection
            key={calculatedSectionId}
            id={calculatedSectionId}
            titleText={sectionName}
          >
            {sectionHeader}
            {sectionTileGrid}
            {mappedSubsections}
          </ObjectPageSection>
        )
      },
    ),
  }
}

export default renderDecisionPaper
