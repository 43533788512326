import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Option,
  Select,
  TableCell,
  TableColumn,
  TableRow,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import DecisionPaperTileTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileTable'
import { timePeriodOptions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewTile'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewTile.module.css'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/ui/Entity'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/useI18n'

const NO_DATA_PLACEHOLDER = '–'

const selectFieldId = 'TenancyOverviewTile__timePeriodSelector'

const MONTHS_IN_A_YEAR = 12

const valueMultiplierFromTimePeriod = new Map([
  ['yearly', 1],
  ['monthly', 1 / MONTHS_IN_A_YEAR],
])

const TenancyOverviewTableRow = ({
  tenant,
  rowTitle,
  rentalUnitsCount,
  usageTypes,
  areaUnit,
  areaSurface,
  areaSurfaceShare,
  numberOfUnits,
  annualizedCurrentRent,
  annualizedCurrentRentPerUom,
  annualizedCurrentRentShare,
  marketRent,
  marketRentPerUom,
  marketRentShare,
  waultToBreakDate,
  waultToBreakInYears,
  waultToExpiryDate,
  waultToExpiryInYears,
  isPdfView,
  columnsIndex,
  ...otherProps
}) => {
  const { t: tTenancyOverview } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.tenancy-overview',
  })
  const rentalUnitsCell = (
    <TableCell data-cell-type="rental-units">
      <Text className={styles.value}>{rentalUnitsCount}</Text>
      {Array.isArray(usageTypes) && usageTypes.length > 0 && (
        <div>
          <Label showColon>{tTenancyOverview('usage-type')}</Label>{' '}
          <Text className={styles.value}>{usageTypes.join(', ')}</Text>
        </div>
      )}
    </TableCell>
  )
  const totalAreaCell = (
    <TableCell data-cell-type="total-area">
      <Text className={styles.value}>{areaSurface}</Text>
      <div>
        <Label showColon>{tTenancyOverview('share')}</Label>{' '}
        <Text className={styles.value}>{areaSurfaceShare}</Text>
      </div>
      <div>
        <Label showColon>{tTenancyOverview('number-of-units')}</Label>{' '}
        <Text className={styles.value}>{numberOfUnits}</Text>
      </div>
    </TableCell>
  )
  const annualizedCurrentRentCell = (
    <TableCell data-cell-type="annualized-current-rent">
      {annualizedCurrentRent !== undefined && (
        <Text className={styles.value}>{annualizedCurrentRent}</Text>
      )}
      {annualizedCurrentRentPerUom !== undefined && (
        <div>
          <Label showColon>{tTenancyOverview('per-uom', { uom: areaUnit })}</Label>{' '}
          <Text className={styles.value}>{annualizedCurrentRentPerUom}</Text>
        </div>
      )}
      {annualizedCurrentRentShare !== undefined && (
        <div>
          <Label showColon>{tTenancyOverview('share')}</Label>{' '}
          <Text className={styles.value}>{annualizedCurrentRentShare}</Text>
        </div>
      )}
    </TableCell>
  )
  const marketRentCell = (
    <TableCell data-cell-type="market-rent">
      {marketRent !== undefined && <Text className={styles.value}>{marketRent}</Text>}
      {marketRentPerUom !== undefined && (
        <div>
          <Label showColon>{tTenancyOverview('per-uom', { uom: areaUnit })}</Label>{' '}
          <Text className={styles.value}>{marketRentPerUom}</Text>
        </div>
      )}
      {marketRentShare !== undefined && (
        <div>
          <Label showColon>{tTenancyOverview('share')}</Label>{' '}
          <Text className={styles.value}>{marketRentShare}</Text>
        </div>
      )}
    </TableCell>
  )
  const waultCell = (
    <TableCell data-cell-type="wault">
      {waultToExpiryInYears !== undefined && (
        <Text className={styles.value}>
          {waultToExpiryInYears} ({waultToExpiryDate})
        </Text>
      )}
      {waultToBreakInYears !== undefined && (
        <div>
          <Label showColon>{tTenancyOverview('wault-break')}</Label>{' '}
          <Text className={styles.value}>
            {waultToBreakInYears} ({waultToBreakDate})
          </Text>
        </div>
      )}
    </TableCell>
  )
  const tableRow = !isPdfView ? (
    <TableRow {...otherProps}>
      <TableCell data-cell-type="tenant">
        {tenant?.id ? (
          <Entity
            name={tenant.fullName}
            id={tenant.id}
            link={`/business-partners/${tenant.id}`}
            openInNewTab
          />
        ) : (
          <Text className={styles.rowTitle}>
            {tenant?.fullName || rowTitle || NO_DATA_PLACEHOLDER}
          </Text>
        )}
      </TableCell>
      {rentalUnitsCell}
      {totalAreaCell}
      {annualizedCurrentRentCell}
      {marketRentCell}
      {waultCell}
    </TableRow>
  ) : (
    <TableRow {...otherProps}>
      <TableCell data-cell-type="tenant">
        <Label className={styles.rowTitle}>
          {tenant?.fullName || rowTitle || NO_DATA_PLACEHOLDER}
        </Label>
        <Text>{tenant?.id}</Text>
      </TableCell>
      {rentalUnitsCell}
      {columnsIndex === 0 && totalAreaCell}
      {columnsIndex === 0 && annualizedCurrentRentCell}
      {columnsIndex !== 0 && marketRentCell}
      {columnsIndex !== 0 && waultCell}
    </TableRow>
  )
  return tableRow
}

TenancyOverviewTableRow.propTypes = {
  className: PropTypes.string,
  areaUnit: PropTypes.string.isRequired,
  tenant: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.string,
  }),
  rowTitle: PropTypes.string,
  rentalUnitsCount: PropTypes.number,
  usageTypes: PropTypes.arrayOf(PropTypes.string),
  areaSurface: PropTypes.string,
  areaSurfaceShare: PropTypes.string,
  numberOfUnits: PropTypes.number,
  annualizedCurrentRent: PropTypes.string,
  annualizedCurrentRentPerUom: PropTypes.string,
  annualizedCurrentRentShare: PropTypes.string,
  marketRent: PropTypes.string,
  marketRentPerUom: PropTypes.string,
  marketRentShare: PropTypes.string,
  waultToBreakInYears: PropTypes.string,
  waultToBreakDate: PropTypes.string,
  waultToExpiryInYears: PropTypes.string,
  waultToExpiryDate: PropTypes.string,
  isPdfView: PropTypes.bool,
  columnsIndex: PropTypes.number,
}

const formatOptionalValue = (value, formatter) =>
  value !== undefined ? formatter(value) : undefined

const TenancyOverviewTileTable = ({
  tenancyOverview,
  timePeriod,
  onTimePeriodChange,
  isPdfView,
}) => {
  const { t: tTenancyOverview } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.tenancy-overview',
  })

  const { keyDate, currency, areaUnit } = tenancyOverview ?? {}

  const { format: formatDate } = useShortDateFormatter()
  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatMoney = useCustomizableCurrencyFormatter({ notation: 'compact' })
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatWaultValue = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const valueMultiplier = valueMultiplierFromTimePeriod.get(timePeriod)

  const formatMoneyValue = useCallback(
    (value) => formatMoney(value * valueMultiplier, currency),
    [currency, formatMoney, valueMultiplier],
  )

  const formatRowValues = useCallback(
    (rowData) =>
      rowData
        ? {
            ...rowData,
            areaUnit: formatOptionalValue(areaUnit, formatAreaUnit),
            areaSurface: formatOptionalValue(
              rowData.areaSurface,
              (value) => `${formatNumber(value)} ${formatAreaUnit(areaUnit)}`,
            ),
            areaSurfaceShare: formatOptionalValue(rowData.areaSurfaceShare, formatPercent),
            annualizedCurrentRent: formatOptionalValue(
              rowData.annualizedCurrentRent,
              formatMoneyValue,
            ),
            annualizedCurrentRentPerUom: formatOptionalValue(
              rowData.annualizedCurrentRentPerUom,
              formatMoneyValue,
            ),
            annualizedCurrentRentShare: formatOptionalValue(
              rowData.annualizedCurrentRentShare,
              formatPercent,
            ),
            marketRent: formatOptionalValue(rowData.marketRent, formatMoneyValue),
            marketRentPerUom: formatOptionalValue(rowData.marketRentPerUom, formatMoneyValue),
            marketRentShare: formatOptionalValue(rowData.marketRentShare, formatPercent),
            waultToBreakInYears: formatOptionalValue(rowData.waultToBreakInYears, formatWaultValue),
            waultToBreakDate: formatOptionalValue(rowData.waultToBreakDate, formatDate),
            waultToExpiryInYears: formatOptionalValue(
              rowData.waultToExpiryInYears,
              formatWaultValue,
            ),
            waultToExpiryDate: formatOptionalValue(rowData.waultToExpiryDate, formatDate),
          }
        : undefined,
    [
      areaUnit,
      formatAreaUnit,
      formatDate,
      formatMoneyValue,
      formatNumber,
      formatPercent,
      formatWaultValue,
    ],
  )

  const formattedTenancyOverview = useMemo(() => {
    if (!tenancyOverview) return undefined
    return {
      ...tenancyOverview,
      topTenants: tenancyOverview.topTenants.map(formatRowValues),
      topTenantsSubtotal: formatRowValues(tenancyOverview.topTenantsSubtotal),
      otherTenants: formatRowValues(tenancyOverview.otherTenants),
      vacancy: formatRowValues(tenancyOverview.vacancy),
      total: formatRowValues(tenancyOverview.total),
    }
  }, [formatRowValues, tenancyOverview])

  const basicColumns = (
    <>
      <TableColumn>
        <Label>{tTenancyOverview('tenant')}</Label>
      </TableColumn>
      <TableColumn>
        <Label>{tTenancyOverview('rental-units')}</Label>
      </TableColumn>
    </>
  )
  const pdfViewFirstPartColumns = (
    <>
      <TableColumn>
        <Label>{tTenancyOverview('total-area')}</Label>
      </TableColumn>
      <TableColumn>
        <Label>{tTenancyOverview('annualized-current-rent')}</Label>
      </TableColumn>
    </>
  )
  const pdfViewSecondPartColumns = (
    <>
      <TableColumn>
        <Label>{tTenancyOverview('market-rent')}</Label>
      </TableColumn>
      <TableColumn>
        <Label>{tTenancyOverview('wault-expiry')}</Label>
      </TableColumn>
    </>
  )
  const columns = !isPdfView
    ? [
        <>
          {basicColumns}
          {pdfViewFirstPartColumns}
          {pdfViewSecondPartColumns}
        </>,
      ]
    : [
        <>
          {basicColumns}
          {pdfViewFirstPartColumns}
        </>,
        <>
          {basicColumns}
          {pdfViewSecondPartColumns}
        </>,
      ]

  if (!tenancyOverview) {
    return <DecisionPaperTileTable className={styles.table} columns={columns} />
  }

  const keyDateUnit = (
    <FlexBox direction={FlexBoxDirection.Row} className={styles.topAreaSide}>
      <Label showColon>{tTenancyOverview('key-date')}</Label>
      <Text>{formatDate(keyDate)}</Text>
    </FlexBox>
  )
  const tenancyOverviewTable = (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.tileContent}>
      {columns.map((column, index) => (
        <DecisionPaperTileTable
          key={`tenancy-overview-table-pdf-view-${isPdfView}-${index}`}
          className={styles.table}
          columns={column}
        >
          {formattedTenancyOverview.topTenants.map((topTenant) => (
            <TenancyOverviewTableRow
              key={topTenant.tenant?.id}
              data-row-type="topTenant"
              {...topTenant}
              rowTitle={tTenancyOverview('anonymous-tenant')}
              isPdfView={isPdfView}
              columnsIndex={index}
            />
          ))}
          {formattedTenancyOverview.topTenantsSubtotal && (
            <TenancyOverviewTableRow
              data-row-type="topTenantsSubtotal"
              {...formattedTenancyOverview.topTenantsSubtotal}
              rowTitle={tTenancyOverview('subtotal')}
              className={styles.highlightedRow}
              isPdfView={isPdfView}
              columnsIndex={index}
            />
          )}
          {formattedTenancyOverview.otherTenants && (
            <TenancyOverviewTableRow
              data-row-type="otherTenants"
              {...formattedTenancyOverview.otherTenants}
              rowTitle={tTenancyOverview('other-tenants', {
                count: formattedTenancyOverview.otherTenantsCount,
              })}
              isPdfView={isPdfView}
              columnsIndex={index}
            />
          )}
          <TenancyOverviewTableRow
            data-row-type="vacancy"
            {...formattedTenancyOverview.vacancy}
            rowTitle={tTenancyOverview('vacancy')}
            isPdfView={isPdfView}
            columnsIndex={index}
          />
          <TenancyOverviewTableRow
            data-row-type="total"
            {...formattedTenancyOverview.total}
            rowTitle={tTenancyOverview('total')}
            className={styles.highlightedRow}
            isPdfView={isPdfView}
            columnsIndex={index}
          />
        </DecisionPaperTileTable>
      ))}
    </FlexBox>
  )

  return (
    <>
      {!isPdfView ? (
        <FlexBox direction={FlexBoxDirection.Column}>
          <FlexBox
            direction={FlexBoxDirection.Row}
            alignItems={FlexBoxAlignItems.Center}
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
            className={styles.tileContent}
          >
            {keyDateUnit}
            <FlexBox
              direction={FlexBoxDirection.Row}
              alignItems={FlexBoxAlignItems.Center}
              className={styles.topAreaSide}
            >
              <Label showColon for={selectFieldId}>
                {tTenancyOverview('rent')}
              </Label>
              <Select
                id={selectFieldId}
                className={styles.select}
                onChange={onTimePeriodChange}
                value={timePeriod}
              >
                {timePeriodOptions.map((option) => (
                  <Option key={option} data-id={option} selected={timePeriod === option}>
                    {tTenancyOverview(`rent.${option}`)}
                  </Option>
                ))}
              </Select>
            </FlexBox>
          </FlexBox>
          <DecisionPaperTileListSeparator />
          {tenancyOverviewTable}
        </FlexBox>
      ) : (
        <>
          <FlexBox direction={FlexBoxDirection.Column}>
            <FlexBox
              direction={FlexBoxDirection.Row}
              alignItems={FlexBoxAlignItems.Center}
              justifyContent={FlexBoxJustifyContent.SpaceBetween}
              className={styles.tileContent}
            >
              {keyDateUnit}
              <FlexBox
                direction={FlexBoxDirection.Row}
                alignItems={FlexBoxAlignItems.Center}
                className={styles.topAreaSide}
              >
                <Label showColon for={selectFieldId}>
                  {tTenancyOverview('rent')}
                </Label>
                <Text>{tTenancyOverview(`rent.${timePeriod}`)}</Text>
              </FlexBox>
            </FlexBox>
            <DecisionPaperTileListSeparator />
            {tenancyOverviewTable}
          </FlexBox>
        </>
      )}
    </>
  )
}

TenancyOverviewTileTable.propTypes = {
  tenancyOverview: PropTypes.object,
  timePeriod: PropTypes.string.isRequired,
  onTimePeriodChange: PropTypes.func.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default TenancyOverviewTileTable
