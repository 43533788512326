import { ObjectPageSection, ObjectPageSubSection } from '@fioneer/ui5-webcomponents-react'

export const objectPageSectionAdapter = ({ staticData, children, key }) => (
  <ObjectPageSection
    key={key}
    id={staticData.id ?? key}
    titleText={staticData.titleText}
    hideTitleText={staticData.hideTitleText}
    wrapTitleText={staticData.wrapTitleText}
    className={staticData.className}
  >
    {children}
  </ObjectPageSection>
)

export const objectPageSubSectionAdapter = ({ staticData, children, key }) => (
  <ObjectPageSubSection
    key={key}
    id={staticData.id ?? key}
    titleText={staticData.titleText}
    hideTitleText={staticData.hideTitleText}
    wrapTitleText={staticData.wrapTitleText}
    className={staticData.className}
  >
    {children}
  </ObjectPageSubSection>
)
