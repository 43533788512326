import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import TenancyOverviewTileTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewTileTable'

export const timePeriodOptions = ['yearly', 'monthly']

const TenancyOverviewTile = ({ tileId, isPdfView }) => {
  const {
    data: { tenancyOverview },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(timePeriodOptions[0])

  const onTimePeriodChange = useCallback((event) => {
    const nextTimePeriod = event?.detail?.selectedOption?.dataset?.id
    if (nextTimePeriod) {
      setSelectedTimePeriod(nextTimePeriod)
    }
  }, [])

  return !isPdfView ? (
    <TenancyOverviewTileTable
      tenancyOverview={tenancyOverview}
      timePeriod={selectedTimePeriod}
      onTimePeriodChange={onTimePeriodChange}
      isPdfView={isPdfView}
    />
  ) : (
    timePeriodOptions.map((option) => (
      <TenancyOverviewTileTable
        key={`tenancy-overview-tile-pdf-view-${option}`}
        tenancyOverview={tenancyOverview}
        timePeriod={option}
        onTimePeriodChange={() => {}}
        isPdfView={isPdfView}
      />
    ))
  )
}

TenancyOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default TenancyOverviewTile
