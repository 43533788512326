import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import { useTranslation } from 'react-i18next'
import { COMPARABLE_STATUS } from 'components/domains/properties/rent-roll/comparison/constants'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'

const useClassifyRentRollComparison = ({
  propertyUuids,
  tenantIds,
  currentKeyDate,
  comparisonKeyDate,
  kpis,
}) => {
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const isMultiProperty = propertyUuids?.length > 1

  const currentRows = kpis.current
  const comparisonRows = kpis.comparison

  const businessPartners = useBusinessPartnerMiniByIds(tenantIds, {
    enabled: !isEmpty(currentRows),
  })

  const findBusinessPartnerNameByIdOrAnonymous = (id) =>
    id === ''
      ? tComparison('table.cells.anonymous-tenant')
      : businessPartners?.data?.businessPartnerMinis?.find((bp) => bp?.id === id)?.fullName

  const findCorrectValueByComparableStatus = (currentValue, compareValue, comparableStatus) => {
    switch (comparableStatus) {
      case COMPARABLE_STATUS.COMPARABLE:
        return currentValue
      case COMPARABLE_STATUS.NEW:
        return currentValue
      case COMPARABLE_STATUS.OLD:
        return compareValue
      case COMPARABLE_STATUS.NOT_COMPARABLE:
        return currentValue
    }
  }

  const convertToCompareAttribute = (currentValue, compareValue) => ({
    current: currentValue ?? 0,
    compare: compareValue ?? 0,
  })

  const convertToClassifiedRow = (currentRow, compareRow, comparableStatus) => {
    const classifiedRow = {
      comparableStatus: comparableStatus,
      tenant: {
        id: findCorrectValueByComparableStatus(
          currentRow?.tenant?.id,
          compareRow?.tenant?.id,
          comparableStatus,
        ),
        name: findCorrectValueByComparableStatus(
          findBusinessPartnerNameByIdOrAnonymous(currentRow?.tenant?.id),
          findBusinessPartnerNameByIdOrAnonymous(compareRow?.tenant?.id),
          comparableStatus,
        ),
        segmentName: findCorrectValueByComparableStatus(
          currentRow?.tenant?.segmentName,
          compareRow?.tenant?.segmentName,
          comparableStatus,
        ),
        usageType: findCorrectValueByComparableStatus(
          currentRow?.tenant?.usageType,
          compareRow?.tenant?.usageType,
          comparableStatus,
        ),
        anonymous: findCorrectValueByComparableStatus(
          currentRow?.tenant?.anonymous,
          compareRow?.tenant?.anonymous,
          comparableStatus,
        ),
      },
      rentalUnits: {
        count: convertToCompareAttribute(
          currentRow?.rentalUnits?.count,
          compareRow?.rentalUnits?.count,
        ),
      },
      totalArea: {
        surface: convertToCompareAttribute(
          currentRow?.totalArea?.surface,
          compareRow?.totalArea?.surface,
        ),
        share: convertToCompareAttribute(
          currentRow?.totalArea?.share,
          compareRow?.totalArea?.share,
        ),
        number_of_units: convertToCompareAttribute(
          currentRow?.totalArea?.number_of_units,
          compareRow?.totalArea?.number_of_units,
        ),
      },
      annualizedCurrentRent: {
        value: convertToCompareAttribute(
          currentRow?.annualizedCurrentRent?.value,
          compareRow?.annualizedCurrentRent?.value,
        ),
        per_uom: convertToCompareAttribute(
          currentRow?.annualizedCurrentRent?.per_uom,
          compareRow?.annualizedCurrentRent?.per_uom,
        ),
        share: convertToCompareAttribute(
          currentRow?.annualizedCurrentRent?.share,
          compareRow?.annualizedCurrentRent?.share,
        ),
      },
      wault: {
        expiry: convertToCompareAttribute(currentRow?.wault?.expiry, compareRow?.wault?.expiry),
        break: convertToCompareAttribute(currentRow?.wault?.break, compareRow?.wault?.break),
      },
    }
    if (isMultiProperty) {
      classifiedRow['property'] = {
        uuid: findCorrectValueByComparableStatus(
          currentRow?.property?.uuid,
          compareRow?.property?.uuid,
          comparableStatus,
        ),
        name: findCorrectValueByComparableStatus(
          currentRow?.property?.name,
          compareRow?.property?.name,
          comparableStatus,
        ),
        typeName: findCorrectValueByComparableStatus(
          currentRow?.property?.typeName,
          compareRow?.property?.typeName,
          comparableStatus,
        ),
      }
    }
    return classifiedRow
  }

  const isMissingProperty = (propertyUuid) =>
    !comparisonRows?.find((kpi) => kpi?.property?.uuid === propertyUuid)

  const propertyUuidsWithoutComparison = compact(
    propertyUuids?.map((propertyUuid) => {
      if (isMissingProperty(propertyUuid)) {
        return propertyUuid
      }
      return null
    }),
  )

  const matchRow = (row, toFind) => {
    if (isMultiProperty) {
      return (
        row.property?.uuid === toFind.property?.uuid &&
        row.tenant?.id === toFind.tenant?.id &&
        row.tenant?.segmentUuid === toFind.tenant?.segmentUuid
      )
    } else {
      return (
        row.tenant?.id === toFind.tenant?.id &&
        row.tenant?.segmentUuid === toFind.tenant?.segmentUuid
      )
    }
  }

  const isNotInComparison = !comparisonKeyDate || comparisonKeyDate === currentKeyDate

  /**
   * The rows are identified by the tenant row object {id, segmentName and usageType}.
   * If in the currentRows and comparisonRows are matchable by tenant, then it is comparable.
   * If there is a row in currentRows that is not in comparisonRows, then it is new.
   * If there is a row in comparisonRows that is not in currentRows, then it is old.
   */
  const classifyCurrentAndComparisonRows = () => {
    if (isNotInComparison) {
      return currentRows.map((currentRow) =>
        convertToClassifiedRow(currentRow, null, COMPARABLE_STATUS.NOT_COMPARABLE),
      )
    }
    const classifiedRows = []
    currentRows.forEach((currentRow) => {
      const comparisonRow = comparisonRows.find((toFind) => matchRow(currentRow, toFind))
      if (isMultiProperty && propertyUuidsWithoutComparison.includes(currentRow.property?.uuid)) {
        classifiedRows.push(
          convertToClassifiedRow(currentRow, null, COMPARABLE_STATUS.NOT_COMPARABLE),
        )
      } else if (comparisonRow) {
        classifiedRows.push(
          convertToClassifiedRow(currentRow, comparisonRow, COMPARABLE_STATUS.COMPARABLE),
        )
      } else {
        classifiedRows.push(convertToClassifiedRow(currentRow, null, COMPARABLE_STATUS.NEW))
      }
    })
    // Otherwise, if we are not able to find a current row in the comparions rows, then it is old
    comparisonRows.forEach((comparisonRow) => {
      const currentRow = currentRows.find((toFind) => matchRow(comparisonRow, toFind))
      if (!currentRow) {
        classifiedRows.push(convertToClassifiedRow(null, comparisonRow, COMPARABLE_STATUS.OLD))
      }
    })
    return classifiedRows
  }

  let data = []
  if (!businessPartners.isLoading && !businessPartners.isError) {
    data = classifyCurrentAndComparisonRows()
  }

  return {
    isLoading: businessPartners.isFetching,
    isError: businessPartners.isError,
    data,
  }
}

export default useClassifyRentRollComparison
