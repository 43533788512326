import { FlexBox, FlexBoxDirection, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyRentRollStructureOverviewTable from 'components/domains/properties/rent-roll/overview/structure-overview/PropertyRentRollStructureOverviewTable'
import styles from 'components/domains/properties/rent-roll/overview/structure-overview/PropertyRentRollStructureOverviewTable.module.css'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import TablesToolbar from 'components/ui/tables/toolbar/TablesToolbar'
import { useSegmentRentalIncomeTableColumns } from 'hooks/services/properties/segments/rental-income/useSegmentRentalIncomeTableColumns'
import { useSegmentRentalIncomeTableRows } from 'hooks/services/properties/segments/rental-income/useSegmentRentalIncomeTableRows'

const PropertyRentRollOverviewRentalIncomeTable = ({
  property,
  segments,
  hasPermission,
  toolbarSelectAction,
}) => {
  const { t: tRentRollOverview } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview',
  })
  const columnDefinitions = useSegmentRentalIncomeTableColumns(property)
  const {
    isError,
    isErrorAllSegmentsPublished,
    data: tableData,
  } = useSegmentRentalIncomeTableRows(property, segments)
  return (
    <>
      <FlexBox
        direction={FlexBoxDirection.Column}
        className={compact([
          styles.messageStripFlex,
          (isErrorAllSegmentsPublished || isError) && styles.messageStripFlexMargin,
        ]).join(' ')}
      >
        {isErrorAllSegmentsPublished && (
          <CloseableMessageStrip design={MessageStripDesign.Negative}>
            {tRentRollOverview('structure-overview.table.linked.error')}
          </CloseableMessageStrip>
        )}
        {isError && (
          <CloseableMessageStrip design={MessageStripDesign.Information}>
            {tRentRollOverview('structure-overview.table.error')}
          </CloseableMessageStrip>
        )}
      </FlexBox>
      <TablesToolbar
        title={tRentRollOverview('rental-income.card.title')}
        additionalActions={[toolbarSelectAction]}
      />
      <PropertyRentRollStructureOverviewTable
        id={'property-rent-roll-segments-rental-income-table'}
        isError={isError}
        isErrorAllSegmentsPublished={isErrorAllSegmentsPublished}
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        hasPermission={hasPermission}
      />
    </>
  )
}
PropertyRentRollOverviewRentalIncomeTable.propTypes = {
  property: PropTypes.object.isRequired,
  segments: PropTypes.array.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  toolbarSelectAction: PropTypes.node.isRequired,
}
export default PropertyRentRollOverviewRentalIncomeTable
