import { createSlice } from '@reduxjs/toolkit'
import { PERIODS } from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'

const initialState = {
  period: PERIODS.ANNUALLY,
}

export const segmentsRentalIncomeSlice = createSlice({
  name: 'properties/rent-roll/segment/rental-income',
  initialState,
  reducers: {
    setPeriod: (state, { payload }) => {
      state.period = payload
    },
    resetPeriod: () => ({ ...initialState }),
  },
})

export const { setPeriod, resetPeriod } = segmentsRentalIncomeSlice.actions

export default segmentsRentalIncomeSlice.reducer
