import {
  FlexBox,
  FlexBoxJustifyContent,
  ListItemType,
  ListMode,
  SelectDialog,
  StandardListItem,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useFinancing from 'hooks/services/deals/financing/useFinancing'
import useFinancingMini from 'hooks/services/deals/financing/useFinancingMini'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import styles from 'routes/deals/financing/shared-components/TranchesSelectDialog/TranchesSelectDialog.module.css'

const TranchesSelectDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  preselectedTrancheIds = [],
  disabledTrancheIds = [],
  onConfirmSelection,
  dealUuid,
  rememberSelections = true,
  forceDataSource,
  isMultiSelect = true,
}) => {
  const { t } = useTranslation()

  const [selectedTrancheIds, setSelectedTrancheIds] = useState(preselectedTrancheIds)
  const [selectedTranches, setSelectedTranches] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const isExistingBusiness = forceDataSource === DATA_SOURCES.EXISTING_BUSINESS

  // Since we need the receivable ids in some existing business use cases,
  // we also need to conditionally call the "full" tranches endpoint (useFinancing).
  // This may have implications for NTK and will be challenged/discussed in an upcoming sprint

  // only used in NB
  const {
    data: { tranchesMinis: tranchesMiniData = [] } = {},
    isFetching: isFinancingMiniLoading,
    isError: isFinancingMiniError,
  } = useFinancingMini(dealUuid, forceDataSource, { enabled: !isExistingBusiness })

  // only used in EB
  const {
    data: { tranches: tranchesData = [] } = {},
    isFetching: isFinancingLoading,
    isError: isFinancingError,
  } = useFinancing(dealUuid, { enabled: isExistingBusiness })

  const setTrancheDetailsByTrancheId = useCallback(
    (trancheIds) => {
      const newSelectedTrancheDetails = trancheIds.map((trancheId) => {
        if (isExistingBusiness) {
          return tranchesData.find(
            ({ externalContractId }) => externalContractId?.[0] === trancheId,
          )
        }
        return tranchesMiniData.find((tranche) => tranche.trancheId === trancheId)
      })
      setSelectedTranches(newSelectedTrancheDetails)
    },
    [isExistingBusiness, tranchesData, tranchesMiniData],
  )

  const onSearch = (event) => {
    const newSearchInput = event.detail.value
    setSearchInput(newSearchInput)
  }

  const onSearchReset = () => {
    setSearchInput('')
  }

  const onConfirm = () => onConfirmSelection(selectedTrancheIds, selectedTranches)

  const onItemClick = (e) => {
    let newSelectedTrancheIds
    const selectedItemTrancheId = e.detail.item.dataset.trancheId

    if (!isMultiSelect) {
      setSelectedTrancheIds([selectedItemTrancheId])
      setTrancheDetailsByTrancheId([selectedItemTrancheId])
      return
    }

    if (selectedTrancheIds.includes(selectedItemTrancheId)) {
      newSelectedTrancheIds = selectedTrancheIds.filter(
        (trancheId) => trancheId !== selectedItemTrancheId,
      )
      setSelectedTrancheIds(newSelectedTrancheIds)
    } else {
      newSelectedTrancheIds = [...selectedTrancheIds, selectedItemTrancheId]
      setSelectedTrancheIds(newSelectedTrancheIds)
    }

    setTrancheDetailsByTrancheId(newSelectedTrancheIds)
  }

  const onClose = () => {
    if (!rememberSelections) {
      setSelectedTrancheIds([])
      setSelectedTranches([])
    }
  }

  const tranchesToShow = useMemo(() => {
    const tranches = isExistingBusiness ? tranchesData : tranchesMiniData
    return tranches?.filter(
      (tranche) =>
        tranche.trancheName.includes(searchInput) ||
        tranche.displayId.includes(searchInput) ||
        tranche.externalContractId?.[0]?.includes(searchInput),
    )
  }, [isExistingBusiness, searchInput, tranchesData, tranchesMiniData])

  return (
    <>
      {createPortal(
        <SelectDialog
          headerText={t('pages.deals.role.tranches-table.write-basic-agreement.dialog-title')}
          open={isDialogOpen}
          mode={!isEmpty(tranchesToShow) ? ListMode.MultiSelect : ListMode.SingleSelect}
          showClearButton={false}
          draggable
          resizable
          onConfirm={onConfirm}
          onSearch={onSearch}
          onSearchReset={onSearchReset}
          onBeforeClose={onClose}
          numberOfSelectedItems={selectedTrancheIds.length}
          className={styles.selectDialog}
          onAfterClose={() => setIsDialogOpen(false)}
          listProps={{ onItemClick }}
          rememberSelections={rememberSelections}
        >
          <RequestStateResolver
            isLoading={isFinancingMiniLoading || isFinancingLoading}
            isError={isFinancingMiniError || isFinancingError}
            center
            errorToDisplay={<ErrorDataUnavailableInContent />}
            renderContent={() =>
              !isEmpty(tranchesToShow) ? (
                tranchesToShow.map((tranche, index) => {
                  const { trancheName, displayId } = tranche ?? {}
                  const trancheId = isExistingBusiness
                    ? tranche.externalContractId?.[0]
                    : tranche.trancheId

                  return (
                    <StandardListItem
                      description={
                        tranche.externalContractIds?.length > 0
                          ? tranche.externalContractIds[0]
                          : null
                      }
                      data-tranche-id={trancheId}
                      key={index}
                      selected={selectedTrancheIds.includes(trancheId)}
                      type={
                        disabledTrancheIds.includes(trancheId)
                          ? ListItemType.Inactive
                          : ListItemType.Active
                      }
                      className={`${
                        disabledTrancheIds.includes(trancheId) ? styles.readonlyListItem : ''
                      } ${styles.dynamicHeight}`}
                    >
                      <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
                        <Text className={styles.trancheNameCell}>
                          {!isEmpty(trancheName) ? trancheName : trancheId}
                        </Text>
                        <Text>{displayId}</Text>
                      </FlexBox>
                    </StandardListItem>
                  )
                })
              ) : (
                <StandardListItem
                  data-description={'no-tranches'}
                  data-testid={'no-tranches-list-item'}
                  selected={false}
                  type={ListItemType.Inactive}
                  className={styles.noTranchesListItem}
                >
                  <FlexBox alignItems={'Center'} justifyContent={FlexBoxJustifyContent.Center}>
                    <Text className={styles.noTranchesListItemText}>
                      {t(
                        'pages.deals.role.tranches-table.write-basic-agreement.dialog.no-tranches',
                      )}
                    </Text>
                  </FlexBox>
                </StandardListItem>
              )
            }
          />
        </SelectDialog>,
        document.body,
      )}
    </>
  )
}

TranchesSelectDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([ListMode.MultiSelect, ListMode.SingleSelect]),
  preselectedTrancheIds: PropTypes.arrayOf(PropTypes.string),
  disabledTrancheIds: PropTypes.arrayOf(PropTypes.string),
  onConfirmSelection: PropTypes.func,
  dealUuid: PropTypes.string.isRequired,
  rememberSelections: PropTypes.bool,
  forceDataSource: PropTypes.oneOf([DATA_SOURCES.NEW_BUSINESS, DATA_SOURCES.EXISTING_BUSINESS]),
  isMultiSelect: PropTypes.bool,
}

export default TranchesSelectDialog
