import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const getRentalUnitWorkingVersionKey = (rentalUnitName, segmentUuid) =>
  `${rentalUnitName ?? ''}-${segmentUuid ?? 'NO-SEGMENT-UUID'}`

const useValidateRentalUnitIds = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.id-validation',
  })
  const validateAndAdjustRentalUnitIds = useCallback(
    (rentalUnits = []) => {
      const rentalUnitsWithoutSameId = []
      rentalUnits.reduce((keys, _rentalUnit) => {
        const rentalUnitNameOrDefault =
          _rentalUnit?.rental_unit_name || t('rental-unit-name-default')
        const rentalUnit = { ..._rentalUnit, rental_unit_name: rentalUnitNameOrDefault }

        const key = getRentalUnitWorkingVersionKey(
          rentalUnitNameOrDefault,
          rentalUnit?.segment_uuid,
        )
        const existingKey = keys.find((preExistingKey) => preExistingKey.key === key)
        if (!existingKey) {
          rentalUnitsWithoutSameId.push(rentalUnit)
          return [...keys, { key: key, index: 0 }]
        }
        existingKey.index += 1
        rentalUnitsWithoutSameId.push({
          ...rentalUnit,
          rental_unit_name: `${rentalUnitNameOrDefault} (${existingKey.index})`,
        })
        return keys
      }, [])
      return rentalUnitsWithoutSameId
    },
    [t],
  )

  return { validateAndAdjustRentalUnitIds }
}

export default useValidateRentalUnitIds
