import {
  Button,
  ButtonDesign,
  ComboBox,
  ComboBoxGroupItem,
  ComboBoxItem,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Form,
  Icon,
  Label,
  Modals,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import find from 'lodash.find'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import BusinessPartnerSearchInput from 'components/domains/business-partners/search-input/BusinessPartnerSearchInput'
import PropertiesSearchDialog from 'components/domains/properties/properties-search/dialog/PropertiesSearchDialog'
import Dialog, { DialogPrimaryButton, DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import MessageBox, { MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import { ConfigContext } from 'hooks/config/useConfig'
import { useCollateralAgreementCreation } from 'hooks/services/collaterals/useCollateralAgreementCreation'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import CagCurrencyFormValue from 'routes/deals/collaterals/creation/CagCurrenyFormValue'
import CagNameFormItem from 'routes/deals/collaterals/creation/CagNameFormItem'
import CagValueFormItem from 'routes/deals/collaterals/creation/CagValueFormItem'
import styles from 'routes/deals/collaterals/creation/CollateralAgreementCreationDialog.module.css'
import CustomFormItem from 'routes/deals/collaterals/creation/CustomFormItem'
import { useMappedCollateralAgreementTypes } from 'routes/deals/collaterals/creation/useMappedCollateralAgreementTypes'
import { useTranchesForCollateralAgreementCreation } from 'routes/deals/collaterals/creation/useTranchesForCollateralAgreementCreation'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import TranchesSelectDialog from 'routes/deals/financing/shared-components/TranchesSelectDialog/TranchesSelectDialog'

const noManualInputAllowedLength = 0

const realEstateLienCategoryId = '000001'
const defaultValueForNoneRealEstateLiens = 0.01
const defaultCurrencyCode = 'EUR'

const defaultFormValues = {
  cagType: '',
  currencyCode: '',
  cagName: '',
  cagValue: 0,
  provider: { id: '', name: '' },
  trancheIds: [],
  properties: [],
}

/**
 * @typedef {object} CollateralProvider
 * @property {string} id
 */

/**
 * Checks whether all required parameters for the external collateral are filled
 * @param {object} param
 * @param {string} param.cagName
 * @param {number} param.cagValue
 * @param {string} param.selectedCagType
 * @param {CollateralProvider?} param.selectedProvider
 * @param {object[]?} param.selectedProperties
 * @param {string} param.selectedCurrencyCode
 *
 * @returns {boolean}
 */
const isValidExternalCag = ({
  cagName,
  cagValue,
  selectedCagType,
  selectedProvider,
  selectedProperties,
  selectedCurrencyCode,
} = {}) =>
  !!cagName &&
  !!cagValue &&
  !!selectedCagType &&
  !!selectedProvider?.id &&
  !!selectedProperties?.length &&
  !isEmpty(selectedCurrencyCode.trim())

/**
 * Checks whether all required parameters for the collateral are filled
 * @param {object} param
 * @param {string} param.cagName
 * @param {number} param.cagValue
 * @param {string} param.selectedCagType
 * @param {CollateralProvider?} param.selectedProvider
 * @param {object[]?} param.financialProducts
 * @param {string} param.selectedCurrencyCode
 *
 * @returns {boolean}
 */
const isValidCag = ({
  cagName,
  cagValue,
  selectedCagType,
  selectedProvider,
  financialProducts,
  selectedCurrencyCode,
} = {}) =>
  !!cagName &&
  !!cagValue &&
  !!selectedCagType &&
  !!selectedProvider?.id &&
  !!financialProducts?.length &&
  !isEmpty(selectedCurrencyCode.trim())

const CollateralAgreementCreationDialog = ({
  open,
  setIsOpen,
  onCancel,
  initialFormValues = defaultFormValues,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.collaterals' })
  const showToast = Modals.useShowToast()

  const [isPropertySearchDialogOpen, setIsPropertySearchDialogOpen] = useState(false)
  const [isTranchesSelectDialogOpen, setIsTranchesSelectDialogOpen] = useState(false)
  const [isRealEstateLien, setIsRealEstateLien] = useState(false)
  const [isErrorMessageBoxOpen, setIsErrorMessageBoxOpen] = useState(false)

  const {
    deal: { dealUuid },
  } = useContext(DealContext)
  const queryClient = useQueryClient()

  const { mutate: createCollateralAgreement, isLoading: isCreatingCag } =
    useCollateralAgreementCreation({
      // trigger a reload of the collaterals after creation by invalidating the query,
      // and open and show a new tab with the newly created cag details
      onSuccess: ({ data }) => {
        showToast({ children: t('collateral-create-success') })

        queryClient.invalidateQueries(['deals', dealUuid, 'tranches', 'dataSource'])
        queryClient.invalidateQueries(['collateral-agreements', 'tranche-ids'])
        window.open(`/${dealDetailPaths.collaterals}/${data?.id}`, '_blank').focus()
      },
      onError: () => {
        setIsErrorMessageBoxOpen(true)
      },
    })

  const config = useContext(ConfigContext)

  const {
    data: { cagTypes = [] } = {},
    isLoading: isCagTypesLoading,
    isError: isCagTypesError,
  } = useMappedCollateralAgreementTypes()

  const [selectedCagType, setSelectedCagType] = useState(initialFormValues.cagType)
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(initialFormValues.currencyCode)
  const [cagName, setCagName] = useState(initialFormValues.cagName)
  const [cagValue, setCagValue] = useState(initialFormValues.cagValue)
  const [selectedProvider, setSelectedProvider] = useState(initialFormValues.provider)
  const [selectedTrancheIds, setSelectedTrancheIds] = useState(initialFormValues.trancheIds)
  const [selectedProperties, setSelectedProperties] = useState(initialFormValues.properties)
  const [isExternalCagType, setIsExternalCagType] = useState(false)

  const resetFormData = useCallback(() => {
    setSelectedCagType(initialFormValues.cagType)
    setSelectedCurrencyCode(initialFormValues.currencyCode)
    setCagName(initialFormValues.cagName)
    setCagValue(initialFormValues.cagValue)
    setSelectedProvider(initialFormValues.provider)
    setSelectedTrancheIds(initialFormValues.trancheIds)
    setSelectedProperties(initialFormValues.properties)
    setIsRealEstateLien(false)
    setIsExternalCagType(false)
  }, [
    initialFormValues.cagName,
    initialFormValues.cagType,
    initialFormValues.cagValue,
    initialFormValues.currencyCode,
    initialFormValues.properties,
    initialFormValues.provider,
    initialFormValues.trancheIds,
  ])

  const { data: { financialProducts = [] } = {} } = useTranchesForCollateralAgreementCreation({
    dealUuid,
    dataSource: DATA_SOURCES.NEW_BUSINESS,
    trancheIds: selectedTrancheIds,
  })

  const isValid = useMemo(() => {
    if (isExternalCagType) {
      return isValidExternalCag({
        cagName,
        cagValue,
        selectedCagType,
        selectedProvider,
        selectedProperties,
        selectedCurrencyCode,
      })
    }

    return isValidCag({
      cagName,
      cagValue,
      selectedCagType,
      selectedProvider,
      financialProducts,
      selectedCurrencyCode,
    })
  }, [
    selectedCagType,
    cagName,
    cagValue,
    selectedProvider,
    financialProducts,
    selectedCurrencyCode,
    selectedProperties,
    isExternalCagType,
  ])

  const handleCreateClick = () => {
    if (!isValid) return
    const type = find(
      cagTypes,
      ({ name, isGroupHeader }) => name === selectedCagType && !isGroupHeader,
    )
    const typeId = type?.id
    createCollateralAgreement({
      name: cagName,
      type: typeId,
      value: cagValue,
      currency: selectedCurrencyCode,
      collateralProviderId: selectedProvider?.id,
      financialProductIds: financialProducts?.map(({ id }) => id),
      propertyUuids: isRealEstateLien ? selectedProperties?.map(({ uuid }) => uuid) : [],
    })
    setIsOpen(false)
  }

  const updateSelectedCagType = (newTypeName) => {
    const newType = find(cagTypes, { name: newTypeName })
    const isNewTypeAnExternalType = Object.values(
      config?.collateralAgreements?.externalTypes ?? {},
    ).includes(newType?.id)
    setIsExternalCagType(isNewTypeAnExternalType)
    if (isNewTypeAnExternalType) {
      setSelectedTrancheIds([])
    }

    setSelectedCagType(newTypeName)
    const isNewTypeARealEstateLien = newType?.categoryId === realEstateLienCategoryId
    setIsRealEstateLien(isNewTypeARealEstateLien)
    const shouldApplyDefaultValueForNoneRealEstateLien = !isNewTypeARealEstateLien && !cagValue
    if (shouldApplyDefaultValueForNoneRealEstateLien) {
      setCagValue(defaultValueForNoneRealEstateLiens)
      setSelectedCurrencyCode(defaultCurrencyCode)
    }
  }

  const handleSelectedPropertyDelete = useCallback(({ detail: { token } }) => {
    const selectedId = token.dataset.id
    setSelectedProperties((currentSelectedEntities) =>
      currentSelectedEntities.filter(({ id }) => id !== selectedId),
    )
  }, [])

  const handleFinancialProductDelete = useCallback(({ detail: { token } }) => {
    const selectedId = token.dataset.id
    setSelectedTrancheIds((prev) => prev.filter((id) => id !== selectedId))
  }, [])

  const selectedProductTokens = useMemo(
    () =>
      financialProducts.map(({ id, name }) => (
        <Token key={id} text={name} data-id={id} closeIcon={<Icon name="decline" />} />
      )),
    [financialProducts],
  )

  const selectedPropertyTokens = useMemo(
    () =>
      selectedProperties.map(({ id, description }) => (
        <Token
          key={id}
          text={`${description} (${id})`}
          data-id={id}
          closeIcon={<Icon name="decline" />}
        />
      )),
    [selectedProperties],
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    handleCreateClick()
  }

  const initialPropertyInputFilterValues = {
    dealId: dealUuid,
    propertyName: '',
    propertyId: '',
    country: '',
    city: '',
    zipCode: '',
    marketId: '',
  }

  return (
    <>
      <Dialog
        open={open}
        onAfterClose={() => {
          resetFormData()
          setIsOpen(false)
        }}
        headerText={t('create-collateral-agreement')}
        primaryButton={
          <DialogPrimaryButton onClick={handleCreateClick} disabled={!isValid}>
            {tNoPrefix('buttons.create')}
          </DialogPrimaryButton>
        }
        closeButton={
          <DialogSecondaryButton onClick={onCancel}>
            {tNoPrefix('buttons.cancel')}
          </DialogSecondaryButton>
        }
      >
        <Form onSubmit={handleSubmit}>
          <FlexBox
            justifyContent={FlexBoxJustifyContent.Start}
            direction={FlexBoxDirection.Column}
            className={styles.uploadForm}
          >
            <FlexBox
              justifyContent={FlexBoxJustifyContent.SpaceBetween}
              className={styles.columnContainer}
            >
              <CagNameFormItem cagName={cagName} setCagName={setCagName} disabled={isCreatingCag} />
              <CustomFormItem className={styles.columnItem}>
                <Label showColon required for="cag-type-combobox">
                  {t('fields.type')}
                </Label>
                <ComboBox
                  id="cag-type-combobox"
                  value={
                    (isCagTypesError && tNoPrefix('components.loading-combo-box.loading')) ||
                    (isCagTypesLoading && tNoPrefix('components.loading-combo-box.loading')) ||
                    selectedCagType
                  }
                  onFocus={(event) => event.stopPropagation()}
                  onChange={(event) => updateSelectedCagType(event.target.value)}
                >
                  {!isCagTypesError && !isCagTypesLoading && <ComboBoxItem text="" />}
                  {cagTypes.map(({ id, name, isGroupHeader }) =>
                    isGroupHeader ? (
                      <ComboBoxGroupItem text={name} key={id} />
                    ) : (
                      <ComboBoxItem text={name} key={id} />
                    ),
                  )}
                </ComboBox>
              </CustomFormItem>
            </FlexBox>
            <FlexBox
              justifyContent={FlexBoxJustifyContent.SpaceBetween}
              className={styles.columnContainer}
            >
              <CagValueFormItem
                cagValue={cagValue}
                setCagValue={setCagValue}
                disabled={isCreatingCag}
                required={true}
              />
              <CagCurrencyFormValue
                currencyCode={selectedCurrencyCode}
                setCurrencyCode={setSelectedCurrencyCode}
                disabled={isCreatingCag}
                required
              />
            </FlexBox>
            <CustomFormItem>
              <Label showColon required>
                {t('fields.collateral-provider')}
              </Label>
              <BusinessPartnerSearchInput value={selectedProvider} onChange={setSelectedProvider} />
            </CustomFormItem>
            <CustomFormItem>
              <Label showColon required={!isExternalCagType}>
                {t('fields.financial-products')}
              </Label>
              <MultiInput
                id="financial-product-search-multi-input"
                maxlength={noManualInputAllowedLength}
                className={styles.uploadFormItem}
                tokens={selectedProductTokens}
                icon={
                  <Button
                    id="open-financial-product-search-button"
                    icon="value-help"
                    design={ButtonDesign.Transparent}
                    onClick={() => {
                      setIsTranchesSelectDialogOpen(true)
                    }}
                    disabled={isExternalCagType}
                  />
                }
                onTokenDelete={handleFinancialProductDelete}
                disabled={isExternalCagType}
              />
            </CustomFormItem>
            <CustomFormItem>
              <Label showColon required={isExternalCagType}>
                {t('fields.properties')}
              </Label>
              <MultiInput
                maxlength={noManualInputAllowedLength}
                id="multi-properties-input-cag-creation"
                className={styles.uploadFormItem}
                tokens={selectedPropertyTokens}
                disabled={!isRealEstateLien}
                icon={
                  <Button
                    id="open-properties-search-button"
                    icon="value-help"
                    design={ButtonDesign.Transparent}
                    onClick={() => {
                      setIsPropertySearchDialogOpen(true)
                    }}
                  />
                }
                onTokenDelete={handleSelectedPropertyDelete}
              />
            </CustomFormItem>
          </FlexBox>
        </Form>
      </Dialog>
      {createPortal(
        <PropertiesSearchDialog
          isOpen={isPropertySearchDialogOpen}
          setIsOpen={setIsPropertySearchDialogOpen}
          allowMultiSelect
          onAccept={setSelectedProperties}
          initiallySelectedProperties={selectedProperties}
          initialFilterCriteria={initialPropertyInputFilterValues}
        />,
        document.body,
      )}
      {isTranchesSelectDialogOpen && ( // required to keep the preselected tranche ids up to date
        <TranchesSelectDialog
          onConfirmSelection={setSelectedTrancheIds}
          isDialogOpen={isTranchesSelectDialogOpen}
          preselectedTrancheIds={selectedTrancheIds}
          setIsDialogOpen={setIsTranchesSelectDialogOpen}
          dealUuid={dealUuid}
          forceDataSource={DATA_SOURCES.NEW_BUSINESS}
        />
      )}
      <MessageBox
        id="cag-create-error-message-box"
        type={MessageBoxTypes.Error}
        open={isErrorMessageBoxOpen}
        onClose={() => setIsErrorMessageBoxOpen(false)}
        titleText={t('collateral-create-error-title')}
      >
        {t('collateral-create-error')}
      </MessageBox>
    </>
  )
}

CollateralAgreementCreationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    cagType: PropTypes.string,
    currencyCode: PropTypes.string,
    cagName: PropTypes.string,
    cagValue: PropTypes.number,
    provider: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    trancheIds: PropTypes.arrayOf(PropTypes.string),
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
}

export default CollateralAgreementCreationDialog
