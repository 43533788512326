import {
  Button,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  TableCell,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import LoadingButton from 'components/ui/button/LoadingButton'
import actionStyles from 'components/ui/tables/display-and-edit-table/DisplayAndEditTableActionCell.module.css'

export const DisplayAndEditTableActionCell = ({
  rowKey,
  handleSaveClick,
  handleCancelClick,
  handleEditClick,
  handleDeleteClick,
  isEdit,
  cardType,
  isValid,
  isWritingToBackend = false,
  userIsAllowedToEdit,
  hideEditButton,
  hideDeleteButton,
  rowChanged,
  styles,
}) => {
  const renderSaveCancelCell = () => (
    <FlexBox
      direction="Row"
      justifyContent="End"
      alignItems="Center"
      fitContainer
      className={styles?.editModeButtons}
    >
      <LoadingButton
        id={`${rowKey}-save`}
        style={{ marginRight: '8px' }}
        design="Emphasized"
        onClick={() => {
          handleSaveClick()
        }}
        renderContent={() => t('buttons.save')}
        disabled={!isValid || !rowChanged}
        isLoading={!!isWritingToBackend}
      />
      <Button
        id={`${rowKey}-cancel-${cardType}`}
        design="Transparent"
        onClick={() => handleCancelClick()}
        disabled={!!isWritingToBackend}
      >
        {t('buttons.cancel')}
      </Button>
    </FlexBox>
  )

  const renderEditDeleteCell = () =>
    userIsAllowedToEdit ? (
      <FlexBox
        justifyContent={FlexBoxJustifyContent.End}
        className={compact([styles?.readModeButtons, actionStyles.readModeButtonsWrapper]).join(
          ' ',
        )}
        alignItems={FlexBoxAlignItems.Center}
      >
        {!hideEditButton && (
          <Button
            id={`${rowKey}-edit`}
            design="Transparent"
            icon="edit"
            onClick={() => handleEditClick()}
          />
        )}
        {!hideDeleteButton && (
          <Button
            id={`${rowKey}-delete-${cardType}`}
            design="Transparent"
            icon="delete"
            onClick={() => handleDeleteClick()}
          />
        )}
      </FlexBox>
    ) : (
      <></>
    )

  return (
    <TableCell
      className={isEdit ? styles?.editModeCell : styles?.readModeCell}
      key={rowKey + '-actionButtons'}
    >
      {isEdit ? renderSaveCancelCell() : renderEditDeleteCell()}
    </TableCell>
  )
}

DisplayAndEditTableActionCell.propTypes = {
  rowKey: PropTypes.string,
  handleSaveClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  userIsAllowedToEdit: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  rowChanged: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    editModeCell: PropTypes.string,
    readModeCell: PropTypes.string,
    editModeButtons: PropTypes.string,
    readModeButtons: PropTypes.string,
  }),
  isWritingToBackend: PropTypes.bool,
}
