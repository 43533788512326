import { useMemo } from 'react'
import useTranches from 'hooks/services/deals/covenants/useTranches'

/**
 * maps the trancheIds to financial products by using the useTranches({dealUuid}) to receive information for the given trancheIds.
 * @param {object} params
 * @param {string} params.dealUuid
 * @param {string} params.dataSource
 * @param {string[]} [params.trancheIds]
 */
export const useTranchesForCollateralAgreementCreation = ({
  dealUuid,
  dataSource,
  trancheIds = [],
}) => {
  const { data: { tranches = [] } = {}, isLoading, isError } = useTranches({ dealUuid, dataSource })

  const financialProducts = useMemo(
    () =>
      tranches
        .filter(({ trancheId }) => trancheIds.includes(trancheId))
        .map(({ displayId, trancheId, trancheName }) => ({
          displayId,
          id: trancheId,
          name: trancheName,
        })),
    [tranches, trancheIds],
  )

  return {
    data: { financialProducts },
    isLoading,
    isError,
  }
}
